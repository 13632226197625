import React from "react"
import { observer } from "mobx-react"
import { Dialog, DialogContent, Link, Slide, useMediaQuery } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import CloseIcon from "@mui/icons-material/Close"
import { AppButton } from "components/common/app-controls/app-button"
import { IArticleFeedItem } from "stores/main-store.types"
import { LayoutColumn } from "./layout-column"
import { useTheme } from "@mui/material/styles"
import { TransitionProps } from "@mui/material/transitions"
import moment from "moment"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />
})

export const MobileDialog = observer(({ articleFeedItem, onClose }: { articleFeedItem: IArticleFeedItem, onClose: () => void }) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Dialog
      open={!!articleFeedItem}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          borderBottomRightRadius: "60px",
          borderBottomLeftRadius: "60px",
          maxWidth: "unset",
          position: "fixed",
          margin: 0,
          top: 0,
          left: 0,
          right: 0
        }
      }}
    >
      <DialogContent>
        <Box p={`${sm ? 6 : 24}px`} pb={0} display="flex" flexDirection="column" justifyContent="space-between" mt={1}>
          <Typography
            variant="body2"
            width={1}
            align="left"
            fontWeight="400"
            fontSize={sm ? 11 : 13}
            color={theme.palette.text.disabled}
          >
            ARTICLE
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography
              variant="body2"
              fontWeight={400}
              align="left"
              fontSize={sm ? 22 : 26}
              color="text.secondary"
              textTransform="none"
              mt={0.5}
              mr={3}
            >
              {articleFeedItem?.headline}
            </Typography>
            <Link href={articleFeedItem?.url} target="_blank" referrerPolicy="no-referrer">
              <OpenInNewIcon color="primary" sx={{width: "36px", height: "36px"}} />
            </Link>
          </Box>
          <Box display="flex" alignItems="start" justifyContent="space-between" mt={3}>
            {articleFeedItem?.sentiment >= 0 ? (
              <LayoutColumn title="SCORE" value={`+ ${articleFeedItem?.sentiment.toFixed(2)}`} color="success.main" />
            ) : (
              <LayoutColumn title="SCORE" value={`- ${(0 - articleFeedItem?.sentiment).toFixed(2)}`} color="error.main" />
            )}
            <LayoutColumn title="EXCHANGE" value={articleFeedItem?.exchangesJoined} />
            <LayoutColumn title="SYMBOLS" value={articleFeedItem?.symbolsJoined} />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" mt={3}>
            <LayoutColumn title="DATE" value={moment(articleFeedItem?.datetime).format("MM/DD/YYYY")} />
            <LayoutColumn title="TIME" value={moment(articleFeedItem?.datetime).format("hh:mma")} />
            <LayoutColumn title="Source" value={articleFeedItem?.source} />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" mt={1}>
            <AppButton color="primary" onClick={() => window.open(articleFeedItem?.url,"_blank", "noopener")}>
              See Article Page
            </AppButton>
            <Box mt={sm ? 3 : 4} mb={sm ? 1 : 2} sx={{cursor: "pointer"}} onClick={onClose}>
              <CloseIcon />
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
})
