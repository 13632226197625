import React from "react"
import { useTheme } from "@mui/material/styles"
import { Dialog, DialogContent, Typography, useMediaQuery } from "@mui/material"
import { PageLogo } from "./page-logo"

export const ProMembersOnlyDialog = ({ open, additionalText }) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Dialog
      open={open} fullWidth hideBackdrop
      sx={{pointerEvents: "none"}}
      PaperProps={{ sx: { borderRadius: "60px" } }} BackdropProps={{ sx: { transition: "none !important" }}}
    >
      <DialogContent sx={{pointerEvents: "all"}}>
        <PageLogo />

        <Typography variant="h6" width={1} align="center" fontWeight="700" mt={3} fontSize={sm ? 15 : 20}>
          PRO MEMBERS ONLY
        </Typography>

        {additionalText && (
          <Typography variant="body2" width={1} align="center" mt={1} fontSize={sm ? 12 : 15}>
            {additionalText}
          </Typography>
        )}

        <a href="https://www.hypeindex.com/pricing" target="_blank">
          <Typography variant="body1" align="center" fontSize={sm ? 12 : 15} my={3}>
            Try HYPEINDEX PRO
          </Typography>
        </a>
      </DialogContent>
    </Dialog>

  )
}
