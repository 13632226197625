import React, { useEffect, useState } from "react"
import moment from "moment"
import Box from "@mui/material/Box"
import MainStore from "stores/main-store"
import UserStore from "stores/user-store"
import { observer } from "mobx-react"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import { useMediaQuery } from "@mui/material"
import { PageLogo } from "../../components/common/page-logo"
import { AppSelectField } from "../../components/common/app-controls/app-select-field"
import { AppButton } from "../../components/common/app-controls/app-button"
import { ProMembersOnlyDialog } from "../../components/common/pro-members-only-dialog"

export const AiReportPage = observer((): JSX.Element => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))
  const [ mainStore ] = useState(() => MainStore)
  const [ userStore ] = useState(() => UserStore)
  const [ selectedAsset, setSelectedAsset ] = useState("")
  const [ isProcessing, setIsProcessing ] = useState(false)
  const [ aiReport, setAiReport ] = useState(undefined)
  const [ showProDialog, setShowProDialog ] = useState(false)
  const [ showPleaseWait, setShowPleaseWait ] = useState(false)

  useEffect(() => {
    setAiReport(undefined)
  }, [ selectedAsset ])

  const dayHasPassed = () => {
    const savedDate = localStorage.getItem("ai_report_date")

    if (!savedDate) return true

    let dateToCompare = moment(savedDate,"DD/MM/YYYY")
    let today = moment().startOf("day")

    return (dateToCompare < today)
  }

  const onGenerateReportClick = async () => {
    if (!userStore.currentUserData?.isProUser && !dayHasPassed()) {
      setShowProDialog(true)
      return
    }
    setIsProcessing(true)
    setTimeout(() => setShowPleaseWait(true), 5000)

    const report  = await mainStore.getAssetAiReport(selectedAsset)
    localStorage.setItem("ai_report_date", moment().startOf("day").format("DD/MM/YYYY"))

    setAiReport(report)
    setIsProcessing(false)
    setShowPleaseWait(false)
  }

  return (
    <>
      <Box
        sx={{padding: aiReport || sm ? 2 : 10, paddingBottom: 0, height: "calc(100% - 30px)", display: "flex", flexDirection: "column", alignItems: "center"}}
        style={{
          filter: (showProDialog) && "blur(5px)",
          userSelect: (showProDialog) && "none",
          pointerEvents: (showProDialog) && "none",
          color: theme.palette.text.primary
        }}
      >
        <PageLogo />

        <Typography variant="body1" width={1} align="center" fontWeight="700" mt={3}>
          Select the Index that interests you
        </Typography>

        <Box mt={2} width={400}>
          <AppSelectField
            value={selectedAsset}
            onChange={setSelectedAsset}
            label="Asset"
            options={mainStore.allAssets.map(a => ({value: a.id.toString(), text: `${a.symbol} - ${a.name}`}))}
            fullWidth
            autoComplete
          />
        </Box>

        {(selectedAsset && !aiReport) && (
          <Box display="flex" alignItems="center" flexDirection="column" width={300}>
            <AppButton
              disabled={isProcessing}
              showLoader={isProcessing}
              fontSize={22}
              background={`${theme.palette.primary.main} ${mainStore.isMobile ? "!important" : ""}`}
              fontColor="#fff"
              onClick={onGenerateReportClick}
            >
              Generate Report
            </AppButton>
          </Box>
        )}

        {showPleaseWait && (
          <Typography variant="body1" width={1} align="center" color="gray" mt={3} className="animate-blink">
            Please wait, this can take a minute...
          </Typography>
        )}

        <Box overflow="auto" height="calc(100%-300px)">
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: aiReport }} sx={{ overflowY: "auto" }} />
        </Box>

      </Box>

      <ProMembersOnlyDialog open={showProDialog} additionalText="Another free report will be available for you tomorrow" />
    </>
  )
})

