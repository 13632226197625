import React, { useState } from "react"
import Box from "@mui/material/Box"
import { useTheme } from "@mui/material/styles"
import { Button, CircularProgress, Typography, useMediaQuery } from "@mui/material"
import { ButtonProps } from "@mui/material/Button/Button"
import MainStore from "stores/main-store"

interface IProps extends ButtonProps {
  showLoader?: boolean
  fontSize?: number
  fontColor?: string
  background?: string
}

export const AppButton = (props: IProps) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Box width={0.8} height="60px" mt={sm ? 3 : 4}>
      <Button
        type="submit"
        size="large"
        fullWidth
        sx={{
          minHeight: "56px", borderRadius: "35px",
          background: props.background || theme.palette.background.default,
          boxShadow: "0 10px 50px rgba(0, 0, 0, 0.15)",
          "&:hover > *": {
            color: `${theme.palette.text.primary} !important`
          }
        }}
        {...props}
      >
        <Typography
          variant="body2"
          fontWeight={100}
          align="center"
          fontSize={props.fontSize || (sm ? 22 : 30)}
          color={props.fontColor || "text.secondary"}
          textTransform="none"
        >
          {props.showLoader ? (
            <CircularProgress
              size={24}
              sx={{
                color: props.fontColor || undefined,
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          ) : (props.children)}
        </Typography>
      </Button>
    </Box>
  )
}
