import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import { Dialog, DialogContent, Link, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { PageLogo } from "../../components/common/page-logo"
import { AppTextField } from "components/common/app-controls/app-text-field"
import { AppButton } from "components/common/app-controls/app-button"
import { UserPageFooter } from "./components/user-page-footer"
import * as Yup from "yup"
import { Form, Formik } from "formik"
import { Disabled } from "components/common/disabled"
import { useNavigate } from "react-router-dom"
import UserStore from "stores/user-store"
import MainStore from "stores/main-store"
import { parseFirebaseErrorMessage } from "utils/helpers"
import { observer } from "mobx-react"

export const LoginPage = observer((): JSX.Element => {
  const navigate = useNavigate()
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))
  const [ mainStore ] = useState(() => MainStore)
  const [ userStore ] = useState(() => UserStore)
  const [ isProcessing, setIsProcessing ] = useState(false)

  useEffect(() => {
    if (userStore.initiated) userStore.logoutUser()
  }, [ userStore.initiated ])

  const onSubmit = async (formData) => {

    setIsProcessing(true)

    try {
      await userStore.loginUser(formData.email, formData.password)
      location.replace("/")
    } catch(ex) {
      const message = parseFirebaseErrorMessage(ex.message)
      mainStore.openToast({
        message: message || "Oops, something happened, please try again",
        type: "error",
        position: { vertical: "top", horizontal: "center" }
      })
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <Dialog open PaperProps={{ sx: { borderRadius: "60px" } }} BackdropProps={{ sx: { transition: "none !important" }}} fullWidth>
      <DialogContent>
        <Formik
          initialValues={{ email: "", password: "" }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={
            Yup.object().shape({
              email: Yup.string()
                .email("Please enter a valid email address")
                .required("Please enter your email"),
              password: Yup.string()
                .required("Please enter your password")
            })}
          onSubmit={onSubmit}
        >
          {({ errors, setValues, values }) => (
            <Form>
              <Disabled when={isProcessing}>
                <Box width={1} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                  <PageLogo />

                  <Typography variant="h6" width={1} align="center" fontWeight="700" mt={3} fontSize={sm ? 15 : 20}>
                    Welcome to HYPEINDEX
                  </Typography>

                  <Typography variant="body1" maxWidth={sm ? 250 : 320} align="center" fontSize={sm ? 12 : 15}>
                    Please enter your credentials to continue
                  </Typography>

                  <Box mt={sm ? 1 : 2} />

                  <AppTextField
                    value={values.email}
                    onChange={(email) => setValues({ ...values, email })}
                    label="Email"
                    placeholder="Enter your email address"
                    error={errors.email as string}
                  />

                  <AppTextField
                    password
                    value={values.password}
                    onChange={(password) => setValues({ ...values, password })}
                    label="Password"
                    placeholder="Enter your password"
                    error={errors.password as string}
                    inlineComponent={
                      <Typography
                        variant="body2"
                        fontWeight={400}
                        pl={1} pt={2}
                        fontSize={sm ? 12 : 15}
                        color="text.secondary"
                        textTransform="none"
                      >
                        Forgot your password? <Link color="success.main" href="/reset-password">Reset now</Link>
                      </Typography>
                    }
                  />

                  <AppButton showLoader={isProcessing}>
                    Login
                  </AppButton>

                  <UserPageFooter footerType="signup" />

                </Box>
              </Disabled>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
})
