import React, { useEffect, useState } from "react"
import MainStore from "stores/main-store"
import Box from "@mui/material/Box"
import { useLocation } from "react-use"
import { Outlet, useNavigate } from "react-router-dom"
import { useTheme } from "@mui/material/styles"
import { Alert, Snackbar } from "@mui/material"
import { observer } from "mobx-react"
import { AddAlert } from "./asset-alerts/add-alert"

import "assets/scss/App.scss"
import { SetupAlerts } from "./asset-alerts/setup-alerts"

export const App = observer(() => {
  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  const [ mainStore ] = useState(() => MainStore)

  useEffect(() => mainStore.getLeadboard(), [])

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search)
      const mode = params.get("mode")

      if (mode === "signIn") {
        navigate(`/finish-signup${location.search}`)
      } else if (mode === "resetPassword") {
        navigate(`/new-password${location.search}`)
      }
    }
  }, [ location.search, location.pathname ])

  useEffect(() => {
    const isMobile = () => window.innerWidth < window.innerHeight || window.innerWidth <= 800 //|| window.innerHeight <= 420
    const deviceWidth = () => {
      let widthBP = theme.breakpoints["xs"]
      for (let key in theme.breakpoints) {
        if (window.innerWidth >= theme.breakpoints[key]) {
          widthBP = theme.breakpoints[key]
        }
      }
      return widthBP
    }
    const onResize = () => {
      mainStore.setIsMobile(isMobile())
      mainStore.setDeviceWidth(deviceWidth())
    }
    window.addEventListener("resize", onResize)
    mainStore.setIsMobile(isMobile())
    return () => window.removeEventListener("resize", onResize)
  }, [])

  return (
    <Box width="100%" display="flex" flexDirection="column" bgcolor="#000">
      <Outlet />
      <AddAlert />
      <SetupAlerts />
      <Snackbar
        open={!!mainStore.toast.open}
        autoHideDuration={6000}
        onClose={() => mainStore.closeToast()}
        anchorOrigin={{
          vertical: mainStore.toast.position?.vertical || "bottom",
          horizontal: mainStore.toast.position?.horizontal || "right"
        }}
      >
        <Alert onClose={() => mainStore.closeToast()} severity={mainStore.toast.type} sx={{ width: "100%" }}>
          {mainStore.toast.message}
        </Alert>
      </Snackbar>
    </Box>
  )
})
