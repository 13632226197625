import React, { useEffect, useState } from "react"
import AlertsStore from "stores/alerts-store"
import Box from "@mui/material/Box"
import {
  Checkbox,
  Dialog,
  DialogContent, FormControl, FormHelperText,
  List,
  ListItem,
  ListItemIcon, ListItemText, Switch,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { Disabled } from "components/common/disabled"
import { observer } from "mobx-react"
import { DialogTopIconButton } from "../common/app-controls/dialog-top-icon-button"
import { Close, HighlightOff } from "@mui/icons-material"
import { AlertTypes } from "stores/alerts-store.types"
import InfoIcon from "@mui/icons-material/Info"
import MainStore from "stores/main-store"

const NotificationsBlackIcon = require("assets/icons/notifications-outline-black.svg")
const NotificationsWhiteIcon = require("assets/icons/notifications-outline-white.svg")

export const SetupAlerts = observer(({}) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))
  const [ alertsStore ] = useState(() => AlertsStore)
  const [ mainStore ] = useState(() => MainStore)
  const [ isSaving ] = useState(false)

  useEffect(() => {
    alertsStore.getAllUserAlerts()
  }, [alertsStore.setupAlertsPopupOpen])

  const onToggleAlertChange = async (alert) => {
    await alertsStore.toggleAlert({ alert })
  }
  const onRemoveAlertClick = async (alert) => {
    await alertsStore.removeUserAlert({ alert })
  }

  const onClose = () => {
    alertsStore.setSetupAlertsPopup({open: false})
  }

  return (
    <Dialog
      open={alertsStore.setupAlertsPopupOpen}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "60px", maxWidth: 600 } }}
      fullWidth
    >
      <DialogContent>
        <Box>
          <Disabled when={isSaving}>
            <Box width={1} display="flex" justifyContent="center" alignItems="center" flexDirection="column">

              <DialogTopIconButton position="right" tooltipTitle="Close" icon={<Close />} onClick={onClose} />

              <Typography variant="h6" width={1} align="center" fontWeight="700" my={2} fontSize={sm ? 15 : 20}>
                Setup Alerts
              </Typography>

              <Typography variant="body1" align="center" fontSize={sm ? 12 : 15}>
                Manage all your asset alerts
              </Typography>

              <Box mt={sm ? 2 : 3} />

              {!alertsStore.allUserAlerts.length ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pt={5}
                  width={1}
                  height={mainStore.isMobile ? 1 : 0.5}
                >
                  <InfoIcon
                    fontSize="large"
                    sx={(theme) => ({ color: theme.palette.text.secondary })}
                  />
                  <Typography variant="body2" color="text.secondary" fontSize={16} fontWeight="bold" py={1}>
                    No data
                  </Typography>
                  <Typography variant="body2" color="text.secondary" fontSize={13}>
                    Add some alerts to see and manage them here
                  </Typography>
                </Box>
              ):(
                <FormControl style={{width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "start", marginLeft: 15}}>
                  <Checkbox checked={alertsStore.browserPushNotifications} onChange={() => alertsStore.toggleBrowserPushNotifications()} />
                  <FormHelperText sx={{ marginLeft: 3 }}>Show browser push notifications</FormHelperText>
                </FormControl>
              )}

              <List sx={{ width: '100%', height: "calc(100vh - 325px)", overflowY: "auto" }}>
                {alertsStore.allUserAlerts.map(alert => (
                  <ListItem key={alert.id}>
                    <ListItemIcon>
                      <img src={mainStore.darkMode ? NotificationsWhiteIcon : NotificationsBlackIcon} alt="alert" width={25} />
                    </ListItemIcon>
                    <ListItemText primary={`${alert.asset?.symbol} - ${alert.asset?.name}`} secondary={`${alert.fieldType} ${AlertTypes[alert.alertType].toLowerCase()} ${alert.testValue}`} />
                    <Tooltip title="Turn On / Off" onChange={() => onToggleAlertChange(alert.id)}>
                      <Box px={1}>
                        <Switch edge="end" defaultChecked={alert.enabled} />
                      </Box>
                    </Tooltip>
                    <Tooltip title="Remove Alert">
                      <Box p={0.5} height="25px" display="flex" alignItems="center" sx={{ cursor: "pointer" }} onClick={() => onRemoveAlertClick(alert.id)}>
                        <HighlightOff />
                      </Box>
                    </Tooltip>
                  </ListItem>
                ))}
              </List>

            </Box>
          </Disabled>
        </Box>
      </DialogContent>
    </Dialog>
  )
})
