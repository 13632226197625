import React from "react"
import Box from "@mui/material/Box"
import { Tooltip } from "@mui/material"

export const DialogTopIconButton = ({icon, position = "left", tooltipTitle = undefined, onClick = () => {}}) => (
  <Tooltip title={tooltipTitle} disableHoverListener={!tooltipTitle}>
    <Box
      p={0.5}
      height="25px"
      display="flex"
      alignItems="center"
      sx={{cursor: "pointer"}}
      position="absolute"
      top={30}
      right={position === "right" ? 30 : undefined}
      left={position === "left" ? 30 : undefined}
      onClick={onClick}
    >
      {icon}
    </Box>
  </Tooltip>
)
