import React, { useState } from "react"
import { Button, Tooltip } from "@mui/material"
import UserStore from "../../../stores/user-store"
import MainStore from "../../../stores/main-store"
import { LoginInfoModal } from "../../../pages/user/login-info"
import { useNavigate } from "react-router-dom"
import { useTheme } from "@mui/material/styles"

const UserBlackIcon = require("assets/icons/user-black.svg")
const UserWhiteIcon = require("assets/icons/user-white.svg")

export const TopUserButton = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [ userStore ] = useState(() => UserStore)
  const [ mainStore ] = useState(() => MainStore)
  const [ showUserInfo, setShowUserInfo ] = useState(false)

  const onUserButtonClick = () => {
    if (userStore.currentUserData?.firebaseId) {
      setShowUserInfo(true)
    } else {
      navigate("/login")
    }
  }

  return (
    <>
      <Tooltip title={"Login"} open={userStore.currentUserData?.firebaseId ? false : undefined}>
        <Button
          type="submit"
          size="large"
          fullWidth
          onClick={onUserButtonClick}
          sx={{
            height: "50px",
            width: "50px",
            minWidth: "50px",
            borderRadius: "35px",
            background: theme.palette.background.default,
            // boxShadow: "0 10px 50px rgba(0, 0, 0, 0.15)",
            margin: "10px 0 10px 0",
            // minWidth: 0,
          }}
        >
          <img src={mainStore.darkMode ? UserWhiteIcon : UserBlackIcon} alt="logo" width={25} />
        </Button>
      </Tooltip>
      <LoginInfoModal show={showUserInfo} onHide={() => setShowUserInfo(false)} />
    </>
  )
}
