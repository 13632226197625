import { globalInstance } from "utils/singleton"

class NetworkService {
  private headers: { [key: string]: string } = {
    // "Authorization": "123",
    "Content-Type": "application/json"
  }

  public migrateDataObjectToCamelCasedKeys(dataObject) {
    if (typeof dataObject !== "object" || !dataObject) {
      return dataObject
    }

    if (Array.isArray(dataObject)) {
      return dataObject.map(v => this.migrateDataObjectToCamelCasedKeys(v))
    }

    const migratedDataObject = {}
    const keys = Object.keys(dataObject)

    keys.forEach(k => {
      const value = dataObject[k]
      const camelCasedKey = k.replace(/_([a-z])/g, (g) => g[1].toUpperCase())

      if (Array.isArray(value)) {
        migratedDataObject[camelCasedKey] = this.migrateDataObjectToCamelCasedKeys(value)
      } else if (typeof value === "object") {
        migratedDataObject[camelCasedKey] = this.migrateDataObjectToCamelCasedKeys(value)
      } else {
        migratedDataObject[camelCasedKey] = value
      }
    })

    return migratedDataObject
  }

  public setToken(value: string) {
    this.headers.Authorization = value
  }

  public async fetchText(input: RequestInfo, init?: RequestInit) {
    const response = await fetch(input, { ...init, headers: { ...init?.headers, ...this.headers } })
    return await response.text()
  }


  public async fetch(input: RequestInfo, init?: RequestInit) {
    const body = init?.body ? JSON.stringify(init?.body) : undefined
    const response = await fetch(input, { ...init, body, headers: { ...init?.headers, ...this.headers } })

    if (init?.method === "PATCH") return true

    const dataObject = await response.json()

    return this.migrateDataObjectToCamelCasedKeys(dataObject)
  }
}

export default globalInstance("networkService", NetworkService)
