import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useTheme } from "@mui/material/styles"
import {
  Box, capitalize,
  Input,
  InputAdornment,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography
} from "@mui/material"
import { useEffect, useState } from "react"
import { observer } from "mobx-react"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { Search } from "@mui/icons-material"
import MainStore from "stores/main-store"
import UserStore from "stores/user-store"
import { IAsset } from "stores/main-store.types"
import CloseIcon from "@mui/icons-material/Close"
import { AddAssetToFavoritesButton } from "../common/add-asset-to-favorites-button"

export const AppTopBarDropDown = observer((): JSX.Element => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { assetId } = useParams()
  const [ mainStore ] = useState(() => MainStore)
  const [ userStore ] = useState(() => UserStore)
  const [ menuOpen, setMenuOpen ] = useState(false)
  const [ selectedAsset, setSelectedAsset ] = useState(0)
  const [ textFilter, setTextFilter ] = useState("")
  const [ assets, setAssets ] = useState([])
  const [ preventBubbling, setPreventBubbling ] = useState(false)

  const isProAsset = mainStore.currentAssetData?.asset?.isPro === true
  const isProUser = selectedAsset === 0 || userStore.currentUserData?.isProUser

  useEffect(() => mainStore.getAllAssets(), [])
  useEffect(() => setSelectedAsset(parseInt(assetId) || 0), [ assetId ])
  useEffect(() => setMenuOpen(false), [ selectedAsset ])
  useEffect(() => {
    const assetTypeFilter = (asset: IAsset) => {
      // if (mainStore.assetType === "all") return true
      if (mainStore.assetType === "crypto") return asset.exchange === "CRYPTO"
      if (mainStore.assetType === "favorites") return userStore.currentUserData?.favoriteAssets.includes(asset.id)
      return asset.exchange !== "CRYPTO"
    }

    if (textFilter.length < 2) { setAssets([]); return }

    setAssets(mainStore.allAssets.filter(a =>
        assetTypeFilter(a) && (
          a.symbol.toLowerCase().includes(textFilter.toLowerCase()) ||
          a.name.toLowerCase().includes(textFilter.toLowerCase())
        )
    ))
  }, [ textFilter, mainStore.assetType, mainStore.allAssets ])

  return (
    <Select
      open={menuOpen}
      onOpen={!preventBubbling ? () => setMenuOpen(true) : undefined}
      onClose={() => setMenuOpen(false)}
      value={selectedAsset}
      IconComponent={!menuOpen ? KeyboardArrowDownIcon : KeyboardArrowUpIcon}
      sx={{
        width: mainStore.isMobile ? "100%" : undefined,
        height: 50,
        borderRadius: 4,
        "& fieldset": { border: "none" },
        ".MuiSelect-select": {
          filter: (isProAsset && !isProUser) && "blur(5px)",
          display: "flex",
          alignItems: "center",
          "& *": {
            fontSize: mainStore.isMobile ? 16 : 22
          }
        },
        minWidth: mainStore.isMobile ? 360 : 500,
        // boxShadow: "0 15.5px 31.8px 0 rgba(255, 158, 57, 0.06)",
        background: theme.palette.background.default
      }}
      MenuProps={{
        // hideBackdrop: true,
        sx: {
          // opacity: menuOpen ? 1 : 0,
          // pointerEvents: menuOpen ? "all" : "none",
          transition: "opacity 0.2s ease-out"
        },
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
        transformOrigin: { vertical: "top", horizontal: "left" },
        PaperProps: {
          sx: {
            minWidth: mainStore.isMobile ? "0 !important" : undefined,
            width: mainStore.isMobile ? "100% !important" : undefined,
            // backgroundImage: "linear-gradient(106deg, #fbfbff 9%, rgba(255, 255, 255, 0.83) 83%)",
            // boxShadow: "0 15.5px 31.8px 0 rgba(255, 158, 57, 0.06)",
            background: theme.palette.background.default,
            overflow: "hidden",
            borderRadius: 4
          }
        }
      }}
    >
      <Box position="relative">
        <CloseIcon
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.text.secondary,
            display: mainStore.isMobile ? undefined : "none",
            zIndex: 10
          })}
          onClick={() => setMenuOpen(false)}
        />
        <Tabs value={mainStore.assetType} textColor={"primary"} onChange={(e, value) => mainStore.setAssetType(value)}>
          {/*<Tab disableRipple sx={{textTransform: "none"}} label="All" value="all" />*/}
          <Tab disableRipple sx={{textTransform: "none"}} label="Stocks" value="stocks" />
          <Tab disableRipple sx={{textTransform: "none"}} label="Crypto" value="crypto" />
          {userStore.currentUserData?.firebaseId && (
            <Tab disableRipple sx={{textTransform: "none"}} label="Favorites" value="favorites" />
          )}
        </Tabs>
        <Input
          fullWidth
          sx={{padding: 1, fontSize: 14}}
          placeholder="Enter your search"
          value={textFilter}
          onChange={(e) => setTextFilter(e.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
          startAdornment={<InputAdornment position="start"><Search /></InputAdornment>}
        />
      </Box>
      <Box maxHeight={485} minWidth={mainStore.isMobile ? 324 : 480} overflow="auto">
        {assets.map(asset => (
          <MenuItem
            selected={assetId === asset.id.toString()}
            key={asset.id}
            value={asset.id}
            onClick={() => navigate(`/hype/${asset.id}`)}
            sx={(theme) => ({
              padding: 1,
              // "&:nth-of-type(odd):not(:hover):not(.Mui-selected)": {
              //   background: "#f3f1f8"
              // },
              "&.Mui-selected": {
                backgroundColor: `${theme.palette.text.disabled} !important`,
                "& span, & p": {
                  color: theme.palette.background.default
                }
              }
            })}
          >
            {userStore.currentUserData?.firebaseId && (
              <AddAssetToFavoritesButton assetId={asset.id} />
            )}
            <Typography variant="button" fontWeight="bold" color="text.secondary" width={70} pl={4}>
              {asset.symbol}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {asset.name}
            </Typography>
          </MenuItem>
        ))}
      </Box>
      <MenuItem key={0} value={0} sx={{ display: "none" }}>
        <Typography variant="body2" color="text.primary" fontWeight="bold" px={2}>
          {capitalize(mainStore.assetType)}
        </Typography>
      </MenuItem>
      {mainStore.allAssets.filter(asset => asset.id === selectedAsset).map(asset => (
        <MenuItem key={asset.id} value={asset.id} sx={{ display: "none" }}>
          {userStore.currentUserData?.firebaseId && (
            <Box onMouseEnter={() => setPreventBubbling(true)} onMouseLeave={() => setPreventBubbling(false)}>
              <AddAssetToFavoritesButton assetId={asset.id} />
            </Box>
          )}
          <Typography variant="button" fontWeight="bold" color="text.primary" width={90} pl={2}>
            {asset.symbol}
          </Typography>
          <Typography variant="body2" color="text.primary" pr={2}>
            {asset.name}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  )
})
