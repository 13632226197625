import React from "react"
import { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import MainStore from "stores/main-store"
import { observer } from "mobx-react"
import { useNavigate } from "react-router-dom"
import { LeaderboardTable } from "./leaderboard-table"

export const LeaderboardPage = observer((): JSX.Element => {
  const navigate = useNavigate()
  const [ mainStore ] = useState(() => MainStore)

  useEffect(() => mainStore.getLeadboard(), [])

  return (
    <Box overflow="auto" height={1} p={mainStore.isMobile ? 2 : 4} pt={0}>
      <LeaderboardTable leaderboardAssets={mainStore.leaderboardAssets} />
    </Box>
  )
})
