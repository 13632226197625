import React, { useEffect } from "react"
import Box from "@mui/material/Box"
import {
  Dialog,
  DialogContent,
  Typography, useMediaQuery
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { PageLogo } from "../../components/common/page-logo"
import { AppTextField } from "components/common/app-controls/app-text-field"
import { AppButton } from "components/common/app-controls/app-button"
import { UserPageFooter } from "./components/user-page-footer"
import { useState } from "react"
import MainStore from "stores/main-store"
import UserStore from "stores/user-store"
import { useNavigate } from "react-router-dom"
import { useLocation } from "react-use"
import { Form, Formik } from "formik"
import { Disabled } from "components/common/disabled"
import * as Yup from "yup"

const ValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter your password"),
  confirmPassword: Yup.string()
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value
    })
})

export const NewPasswordPage = ({}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const [ mainStore ] = useState(() => MainStore)
  const [ userStore ] = useState(() => UserStore)
  const [ isSaving, setIsSaving ] = useState(false)
  const [ oobCode, setOobCode ] = useState("")

  const onInit = async () => {
    try {
      if (!location.search) navigate("/signup")

      const params = new URLSearchParams(location.search)
      const code = params.get("oobCode")

      await userStore.validateResetPasswordLink(code)
      navigate("/new-password")
      setOobCode(code)
    } catch (ex) {
      mainStore.openToast({
        message: "The verification link is not valid, please verify your email again",
        type: "error",
        position: { vertical: "top", horizontal: "center" }
      })
      navigate("/reset-password")
    }
  }

  useEffect(() => { onInit() }, [])

  const onSubmit = async (formData) => {
    setIsSaving(true)
    try {
      await userStore.resetPassword(oobCode, formData.password)
      navigate("/login")
    } catch (ex) {
      mainStore.openToast({
        message: "Oops, something happened, please try again",
        type: "error",
        position: { vertical: "top", horizontal: "center" }
      })
    }
  }

  return (
    <Dialog open PaperProps={{ sx: { borderRadius: "60px" } }} BackdropProps={{ sx: { transition: "none !important" }}} fullWidth>
      <DialogContent>
        <Formik
          initialValues={{ password: "", confirmPassword: "" }}
          validationSchema={ValidationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
        >
          {({ errors, setValues, values }) => (
            <Form>
              <Disabled when={isSaving}>
                <Box width={1} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                  <PageLogo />

                  <Typography variant="h6" width={1} align="center" fontWeight="700" mt={5} fontSize={sm ? 15 : 20}>
                    Great!
                  </Typography>

                  <Typography variant="body1" maxWidth={sm ? 250 : 320} align="center" fontSize={sm ? 12 : 15}>
                    You've been verified successfully, <br /> now let's continue resetting your password
                  </Typography>

                  <Box mt={sm ? 1 : 2} />

                  <AppTextField
                    label="Password"
                    placeholder="Enter your password"
                    value={values.password}
                    onChange={(password) => setValues({ ...values, password })}
                    error={errors.password as string}
                    password
                  />

                  <AppTextField
                    label="Confirm Password"
                    placeholder="Re-Enter your password"
                    value={values.confirmPassword}
                    onChange={(confirmPassword) => setValues({ ...values, confirmPassword })}
                    error={errors.confirmPassword as string}
                    password
                  />

                  <AppButton showLoader={isSaving}>
                    Reset Password
                  </AppButton>

                  <UserPageFooter footerType="signup" />

                </Box>
              </Disabled>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
