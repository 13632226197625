import React, { useEffect, useState } from "react"
import {
  Button, Divider, Input, InputAdornment, ListItemIcon, MenuItem, MenuList, OutlinedInput, Popover, TextField, Tooltip
} from "@mui/material"
import Box from "@mui/material/Box"
import {
  AccountCircle,
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  CropSquare, IndeterminateCheckBox,
  Search
} from "@mui/icons-material"
import capitalize from "@mui/utils/capitalize"

type Props = {
  anchorEl: any
  filterType: "range" | "list" | "sentiment"
  listOptions: string[]
  chosenOptions: string[]
  onClose: () => void
  onFilter: (filterData: string[]) => void
  fixedHeight?: boolean
}

let initiated = false
export const FilterPopover = (props: Props) => {
  const [ rangeValues, setRangeValues ] = useState([])
  const [ options, setOptions ] = useState([])
  const [ searchText, setSearchText ] = useState("")

  useEffect(() => {
    if (initiated) return

    initiated = true

    if (props.filterType === "list") {
      if (!props.listOptions?.length) return
      setOptions([ ...props.listOptions ])
    }

    if (!props.chosenOptions?.length) {
      props.onFilter?.([ ...props.listOptions ])
      setRangeValues([ ...props.listOptions ])
    }

  }, [ props.listOptions ])

  useEffect(() => {
    if (props.filterType === "range") {
      setRangeValues([ ...props.chosenOptions ])
    }
  }, [ props.chosenOptions ])

  useEffect(() => {
    if (!searchText) {
      setOptions([ ...props.listOptions ])
    } else {
      setOptions([ ...props.listOptions.filter(op => op.toLowerCase().includes(searchText.toLowerCase())) ])
    }
  }, [ searchText ])

  const onClose = () => {
    initiated = false
    props.onClose?.()
  }

  const onReset = () => {
    props.onFilter?.([ ...props.listOptions ])
    setSearchText("")
    setRangeValues([ ...props.listOptions ])
    // onClose()
  }

  const onAllClick = () => {
    if (props.chosenOptions.length === options.length) { // all selected
      props.onFilter?.([])
    } else if (!props.chosenOptions.length) { // none selected
      props.onFilter?.([ ...options ])
    } else {
      props.onFilter?.([ ...options ])
    }
  }

  return (
    <Popover
      open={Boolean(props.anchorEl)}
      anchorEl={props.anchorEl}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 5,
          height: props.fixedHeight ? "calc(100% - 170px)" : undefined,
          overflow: props.fixedHeight ? "hidden" : undefined
        }}}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Button onClick={onReset} fullWidth>RESET</Button>
      {props.filterType === "list" && (
        <>
          <Box display="flex" px={3} mb={3} mt={1} alignItems="center">
            <Tooltip title={props.chosenOptions.length === options.length ? "Clear selection" : "Select all"}>
              <Box sx={{ cursor: "pointer" }} onClick={onAllClick}>
                {props.chosenOptions.length === options.length ? (
                  <Check fontSize="small" sx={{ marginRight: 1 }} />
                ) : !props.chosenOptions.length ? (
                  <CheckBoxOutlineBlank fontSize="small" sx={{ marginRight: 1 }} />
                ) : (
                  <IndeterminateCheckBox fontSize="small" sx={{ marginRight: 1 }} />
                )}
              </Box>
            </Tooltip>

            <OutlinedInput
              startAdornment={<InputAdornment position="start"><Search/></InputAdornment>}
              type="text" size="small" autoFocus
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />

          </Box>

          <Divider/>
        </>
      )}
      {/*<Box width={1}>*/}
      {/*</Box>*/}

      <Box p={props.filterType === "list" ? 1 : 2} fontSize={10} overflow="auto" height={props.fixedHeight ? "calc(100% - 120px)" : undefined}>
        {props.filterType === "list" ? (
          <MenuList dense>
            {options.map(op => (
              <MenuItem
                key={op}
                onClick={() => {
                  const chosenOptions = [ ...props.chosenOptions ]
                  if (chosenOptions.includes(op)) {
                    const idx = chosenOptions.indexOf(op)
                    chosenOptions.splice(idx, 1)
                  } else {
                    chosenOptions.push(op)
                  }
                  props.onFilter?.(chosenOptions)
                }}
              >
                {props.chosenOptions.includes(op) ? (
                  <Check fontSize="small" sx={{ marginRight: 1 }} />
                ) : (
                  <CheckBoxOutlineBlank fontSize="small" sx={{ marginRight: 1 }} />
                )}
                {capitalize(op)}
              </MenuItem>
            ))}
          </MenuList>
        ) : (props.filterType === "range" ? (
          <Box display="flex" flexDirection="column">
            <TextField
              placeholder="From" label="From" type="number" value={rangeValues[0]}
              onChange={(e) => {
                setRangeValues([ e.target.value, rangeValues[1] ])
              }}
              onBlur={() => {
                props.onFilter?.([ parseFloat(rangeValues[0]), parseFloat(rangeValues[1]) ])
              }}
            />
            <br/>
            <TextField
              placeholder="To" label="To" type="number" value={rangeValues[1]}
              onChange={(e) => {
                setRangeValues([ rangeValues[0], e.target.value ])
              }}
              onBlur={() => {
                props.onFilter?.([ parseFloat(rangeValues[0]), parseFloat(rangeValues[1]) ])
              }}
            />
          </Box>
        ) : null)}
      </Box>
    </Popover>
  )
}
