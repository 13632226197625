import React, { useState } from "react"
import Box from "@mui/material/Box"
import { useTheme } from "@mui/material/styles"
import { useMediaQuery } from "@mui/material"
import MainStore from "../../stores/main-store"

const IconLogoBlack = require("assets/logo/hypeindex-icon-logo-black.svg")
const TextLogoBlack = require("assets/logo/hypeindex-text-black.svg")
const IconLogoWhite = require("assets/logo/hypeindex-icon-logo-white.svg")
const TextLogoWhite = require("assets/logo/hypeindex-text-white.svg")

export const PageLogo = ({}) => {
  const [ mainStore ] = useState(() => MainStore)
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box width={1} display="flex" justifyContent="center" mt={3}>
      <Box width={sm ? 180 : 235} display="flex" justifyContent="space-between">
        {mainStore.darkMode ? (
          <>
            <img src={TextLogoWhite} alt="logo" width={sm ? 145 : 190} />
            <img src={IconLogoWhite} alt="logo" width={sm ? 22 : 30} />
          </>
        ) : (
          <>
            <img src={TextLogoBlack} alt="logo" width={sm ? 145 : 190} />
            <img src={IconLogoBlack} alt="logo" width={sm ? 22 : 30} />
          </>
        )}
      </Box>
    </Box>
  )
}
