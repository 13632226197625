import React from "react"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Typography from "@mui/material/Typography"
import { visuallyHidden } from "@mui/utils"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Badge, Slide, TablePagination, Tooltip } from "@mui/material"
import { useEffect, useState } from "react"
import MainStore from "stores/main-store"
import { observer } from "mobx-react"

function descendingComparator<T>(a: T, b: T, orderBy: keyof T, orderBy2: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  if (b[orderBy2] < a[orderBy2]) {
    return -1
  }
  if (b[orderBy2] > a[orderBy2]) {
    return 1
  }
  return 0
}

type OrderDirection = "asc" | "desc"

function getComparator<Key extends keyof any>(
  order: OrderDirection,
  orderBy: Key,
  orderBy2: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, orderBy2)
    : (a, b) => -descendingComparator(a, b, orderBy, orderBy2)
}

// This method is created for cross-browser compatibility, if you don"t
// need to support IE11, you can use Array.prototype.sort() directly
function tableSort(array: ITableDataItem[], comparator: (a: ITableDataItem, b: ITableDataItem) => number) {
  const stabilizedThis = array.map((el: ITableDataItem, index: number) => [ el, index ])
  stabilizedThis.sort((a: [ITableDataItem, number], b: [ITableDataItem, number]) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export interface IHeadCell {
  disablePadding: boolean
  id: keyof ITableDataItem
  label: string | React.ReactElement
  info?: string
  numeric: boolean
  display?: boolean
  displayOnMobile?: boolean
  overflow?: string
  tooltip?: boolean | string
  width?: string
  color?: string
  align?: "inherit" | "left" | "center" | "right" | "justify"
  renderValue?: (row: ITableDataItem) => any
  blurCell?: (row: ITableDataItem) => any
}

interface IEnhancedTableHeadProps {
  tableCells: IHeadCell[]
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ITableDataItem) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: OrderDirection
  orderBy: string
  rowCount: number
}

export interface ITableDataItem {
  id: number
  isNew?: boolean
  [ key: string ]: any
}

interface IEnhancedTableProps {
  tableCells: IHeadCell[]
  tableData: ITableDataItem[]
  filter: (dataItem: ITableDataItem) => boolean
  orderByDefault: string
  onRowClick?: (row: ITableDataItem) => void
}

function EnhancedTableHead(props: IEnhancedTableHeadProps) {
  const { onSelectAllClick, tableCells, order, orderBy, numSelected, rowCount, onRequestSort } =
    props
  const createSortHandler =
    (property: keyof ITableDataItem) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow>
        {tableCells.filter(a => a.display !== false).map((headCell, index) => {
          const align = headCell.align || "center"
          return (
            <TableCell
              key={headCell.id}
              align={align}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={(theme) => ({
                borderBottom: "none",
                display: headCell.display,
                [theme.breakpoints.down('lg')]: {
                  display: headCell.displayOnMobile === false ? "none" : undefined
                }
              })}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={{ left: (index > 0 && align === "center") && 12 }}
                IconComponent={ArrowDropDownIcon}
              >
                <Typography variant="overline" whiteSpace="nowrap">{headCell.label}</Typography><br />
                {headCell.info && (
                  <Typography
                    variant="overline"
                    fontSize="x-small"
                    color="text.secondary"
                    sx={{ position: "absolute", bottom: -14, left: -16 }}
                  >
                    ({headCell.info})
                  </Typography>
                )}
                {orderBy === headCell.id && (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                )}
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export const EnhancedTable = observer((props: IEnhancedTableProps) => {
  const orderByDefault = props.orderByDefault.split("|")[0]
  const orderDirDefault = (props.orderByDefault.split("|")[1] || "asc") as OrderDirection
  const [ order, setOrder ] = React.useState<OrderDirection>(orderDirDefault)
  const [ orderBy, setOrderBy ] = React.useState<keyof ITableDataItem>(orderByDefault)
  const [ orderBy2, setOrderBy2 ] = React.useState<keyof ITableDataItem>(orderByDefault)
  const [ selected, setSelected ] = React.useState<readonly number[]>([])
  const [ page, setPage ] = React.useState(0)
  const [ dense, setDense ] = React.useState(false)
  const [ rowsPerPage, setRowsPerPage ] = React.useState(5)
  const [ mainStore ] = useState(() => MainStore)

  useEffect(() => {
    if (mainStore.isMobile) {
      setRowsPerPage(2000)
    } else {
      setRowsPerPage(25)
    }
    setPage(0)
  }, [ mainStore.isMobile ])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ITableDataItem
  ) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
    setOrderBy2("hype")
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = props.tableData.map((a) => a.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (id: number) => selected.indexOf(id) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.tableData.length) : 0

  const sortedTableData = tableSort(props.tableData.filter(props.filter), getComparator(order, orderBy, orderBy2))
  const pagedTableData = sortedTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  return (
    <>
      <TableContainer sx={{ overflow: "visible" }}>
        <Table sx={{ borderCollapse: "separate", borderSpacing: "0 15px" }} aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={props.tableData.length}
            tableCells={props.tableCells}
          />
          <TableBody>
            {/* if you don"t need to support IE11, you can replace the `tableSort` call with:
            rows.slice().sort(getComparator(order, orderBy)) */}
            {
              pagedTableData.map((row: ITableDataItem, i) => {
                const isItemSelected = isSelected(row.id)

                return (
                  <Slide direction="right" in={true} key={row.id} timeout={{enter: row.isNew ? 800 : 0}}>
                    <TableRow
                      onClick={(event) => !!props.onRowClick && props.onRowClick(row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={(theme) => ({
                        position: "relative",
                        boxShadow: "-17.5px -0.5px 57px 0 rgba(126, 123, 160, 0.1)",
                        background: row.isNew && (mainStore.darkMode ? "#3b3b34" : "#fffde5"),
                        cursor: "pointer",
                        fontSize: "20px",
                        borderRadius: "10px",
                        "&:hover": { background: theme.palette.background.default },
                        "td, th": {
                          height: 18,
                          padding: "14px"
                        },
                        [theme.breakpoints.down('xl')]: { fontSize: "16px" }
                      })}
                    >
                      {props.tableCells.filter(a => a.display !== false).map((tableCell, index) => {
                        const blurCell = tableCell.blurCell?.(row)
                        let title = !!tableCell.renderValue && tableCell.renderValue(row)
                        if (typeof title !== "string") {
                          title = row[tableCell.id]
                        }
                        return (
                          <TableCell
                            key={tableCell.id}
                            component="th"
                            scope="row"
                            // title={title}
                            align={tableCell.align || "center"}
                            sx={(theme) => ({
                              filter: blurCell && "blur(5px)",
                              pointerEvents: tableCell.id === "alert" && "none",
                              userSelect: blurCell && "none",
                              borderBottom: "none",
                              borderTopLeftRadius: index === 0 ? 10 : 0,
                              borderBottomLeftRadius: index === 0 ? 10 : 0,
                              borderTopRightRadius: index === props.tableCells.length - 1 ? 10 : 0,
                              borderBottomRightRadius: index === props.tableCells.length - 1 ? 10 : 0,
                              fontSize: "inherit",
                              width: tableCell.width,
                              maxWidth: 100,
                              [theme.breakpoints.down('lg')]: {
                                display: tableCell.displayOnMobile === false ? "none" : undefined,
                              }
                            })}
                          >
                            <Tooltip
                              disableHoverListener={!tableCell.tooltip}
                              title={(
                                <Typography>
                                  {typeof tableCell.tooltip === "string" ? tableCell.tooltip : title}
                                </Typography>
                              )}
                              // placement="left"
                            >
                              {function() {
                                const value = tableCell.renderValue ? tableCell.renderValue(row) : row[tableCell.id]
                                if (typeof value === "string" || typeof value === "number") {
                                  return (
                                    <Typography
                                      fontSize="inherit"
                                      color={tableCell.color || "text.secondary"}
                                      fontWeight="300"
                                      whiteSpace="nowrap"
                                      overflow={tableCell.overflow || "hidden"}
                                      textOverflow="ellipsis"
                                      display="block"
                                      width="100%"
                                      component="span"
                                    >
                                      {value}
                                    </Typography>
                                  )
                                } else {
                                  return (
                                    <Box display="flex" justifyContent={tableCell.align}>
                                      {value}
                                    </Box>
                                  )
                                }
                              }()}
                            </Tooltip>
                          </TableCell>
                        )})}
                    </TableRow>
                  </Slide>
                )
              })
            }
            {emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!mainStore.isMobile && (
        <TablePagination
          rowsPerPageOptions={[ 5, 10, 25, 50, 100 ]}
          component="div"
          count={props.tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  )
})
