import React from "react"
import { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { Outlet, useNavigate } from "react-router-dom"
import { useLocation } from "react-use"
import { Box, Fade } from "@mui/material"
import { AppTopBar } from "./app-top-bar/app-top-bar"
import { AppLeftMenu } from "./app-left-menu"
import { PageLoader } from "components/page-loader/page-loader"
import MainStore from "stores/main-store"
import UserStore from "stores/user-store"
import { useTheme } from "@mui/material/styles"

const FullLogoBlack = require("assets/logo/full-logo-black.svg")

const DESKTOP_TOP_BAR_HEIGHT = 100
const MOBILE_TOP_BAR_HEIGHT = 150

export const AppLayout = observer((): JSX.Element => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const [ mainStore ] = useState(() => MainStore)
  const [ userStore ] = useState(() => UserStore)
  const [ isSmallAndWideScreen, setIsSmallAndWideScreen ] = useState(false)
  const [ showLoader, setShowLoader ] = useState(true)
  const [ locationPath, setLocationPath ] = useState("")
  const topBarHeight = location.pathname.startsWith("/ai-report") ? 0 : mainStore.isMobile ? MOBILE_TOP_BAR_HEIGHT : DESKTOP_TOP_BAR_HEIGHT
  const locationChanged = location.pathname !== locationPath && !(locationPath.includes("/article") && location.pathname.includes("/article"))

  const bounceToLogin = () => {
    if (localStorage.getItem("email_to_reg")) {
      navigate("signup")
    } else {
      navigate("login")
    }
  }

  useEffect(() => {
    if (!userStore.initiated) return
    setTimeout(() => {
      setShowLoader(false)
      if (!userStore.currentUserData) {
        const clickCount = 50
        let clicks = parseInt(localStorage.getItem("clicks") || "0")

        if (clicks >= clickCount) {
          bounceToLogin()
          return
        }
        const onDocumentClick = () => {
          clicks++
          if (clicks >= clickCount) {
            bounceToLogin()
            localStorage.setItem("clicks", clickCount.toString())
          }
        }
        addEventListener("click", onDocumentClick)
      } else {
        localStorage.removeItem("clicks")
      }
    }, 2000)
  }, [ userStore.initiated ])

  useEffect(() => {
    setTimeout(() => setLocationPath(location.pathname), 500)
  }, [ location.pathname ])

  useEffect(() => {
    setIsSmallAndWideScreen(window.innerHeight <= 420)
    const onResize = () => setIsSmallAndWideScreen(window.innerHeight <= 420)
    addEventListener("resize", onResize)
    return () => removeEventListener("resize", onResize)
  } ,[])


  if (showLoader) return <PageLoader />

  return (
    <Box
      position="absolute"
      width="100%"
      height="100%"
      minWidth={360}
      top={0}
      left={0}
      display="flex"
      flexDirection="row"
      overflow="hidden"
    >

      <AppLeftMenu />

      <Box width="100%" display="flex" flexDirection="column" bgcolor="background.paper">

        <Box
          position="absolute"
          top={0}
          left={0}
          bottom={0}
          right={0}
          display={locationChanged ? "flex" : "none"}
          alignItems="center"
          justifyContent="center"
          sx={{ opacity: 0.1 }}
        >
          <img src={FullLogoBlack} alt="logo" title="logo" width={mainStore.isMobile ? 280 : 400} />
        </Box>

        {!location.pathname.startsWith("/ai-report") && (
          <AppTopBar isSmallAndWideScreen={isSmallAndWideScreen} />
        )}

        <Box width="100%" height={`calc(100% - ${topBarHeight}px)`} overflow="visible" display="flex" justifyContent="center" position="relative">
          {/*<ArticleListDrawer open={open} />*/}
          <Fade in={!locationChanged} timeout={!locationChanged ? 800 : 0}>

            <Box width="100%" overflow="visible" pb={mainStore.isMobile && !isSmallAndWideScreen ? 6 : 0}>
              {/*<Box*/}
              {/*  width={1}*/}
              {/*  height={120}*/}
              {/*  sx={{*/}
              {/*    position: "fixed",*/}
              {/*    top: 100,*/}
              {/*    backgroundImage: "linear-gradient(to top, rgba(251, 251, 251, 0), #fafafb 77%)",*/}
              {/*    pointerEvents: "none",*/}
              {/*    zIndex: 1000*/}
              {/*  }}*/}
              {/*/>*/}


              <Outlet />

              <Box
                width={1}
                height={mainStore.isMobile ? 80 : 150}
                sx={{
                  position: "fixed",
                  bottom: (mainStore.isMobile ? 20 : -40),
                  backgroundImage: `linear-gradient(to bottom, rgba(251, 251, 251, 0), ${theme.palette.background.paper} 77%)`,
                  pointerEvents: "none",
                  zIndex: 10
                }}
              />
            </Box>

          </Fade>

        </Box>

      </Box>

    </Box>
  )
})
