import React, {useEffect, useState} from "react"
import UserStore from "stores/user-store"
import MainStore from "stores/main-store"
import Box from "@mui/material/Box"
import { Dialog, DialogContent, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { PageLogo } from "../../components/common/page-logo"
import { AppTextField } from "components/common/app-controls/app-text-field"
import { AppButton } from "components/common/app-controls/app-button"
import { UserPageFooter } from "./components/user-page-footer"
import { Disabled } from "components/common/disabled"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { useNavigate } from "react-router-dom"
import { parseFirebaseErrorMessage } from "utils/helpers"
import { useLocation } from "react-use"

export const SignUpPage = ({}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))
  const [ userStore ] = useState(() => UserStore)
  const [ mainStore ] = useState(() => MainStore)
  const [ isSaving, setIsSaving ] = useState(false)
  const [ isDone, setIsDone ] = useState(false)
  const [ initialEmail, setInitialEmail ] = useState("")

  useEffect(() => {
    if (location.search) {
      try {
        // const params = new URLSearchParams(location.search)
        // const email = params.get("email")
        const email = decodeURIComponent(location.search.split("email=")[1])

        if (email && email !== "undefined") {
          // onSubmit({email}, true)
          localStorage.setItem("clicks", "0")
          localStorage.setItem("email_to_reg", email)
          // setInitialEmail(email)
          // setIsDone(true)
          navigate("/leaderboard")
        }
      } catch {}
    }
  }, [ location.search ])

  useEffect(() => {
      try {
        let email = decodeURIComponent(location.search.split("email=")[1])

        if (email && email !== "undefined") return

        email = localStorage.getItem("email_to_reg")

        if (email) {
          localStorage.removeItem("email_to_reg")
          setInitialEmail(email)
        }
      } catch {}
  }, [  ])

  const onSubmit = async (formData, initial = false) => {

    setIsSaving(true)

    try {
      await userStore.sendConfirmationEmail(formData.email)
      setIsDone(true)
    } catch(ex) {
      if (ex.code === "auth/email-already-in-use") {
        mainStore.openToast({
          message: "The email you've entered already exists, Try to login instead",
          type: "error",
          position: { vertical: "top", horizontal: "center" }
        })
      } else {
        const message = parseFirebaseErrorMessage(ex.message)
        mainStore.openToast({
          message: message || "Oops, something happened, please try again",
          type: "error",
          position: { vertical: "top", horizontal: "center" }
        })
      }
      navigate("/login")
    } finally {
      if (!initial) setIsSaving(false)
    }
  }

  const PleaseCheckEmail = () => (
    <Dialog open PaperProps={{ sx: { borderRadius: "60px" } }} BackdropProps={{ sx: { transition: "none !important" }}} fullWidth>
      <DialogContent>
        <Box width={1} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <PageLogo />

          <Typography variant="h6" width={1} align="center" fontWeight="700" mt={5} fontSize={sm ? 15 : 20}>
            We're on the way!
          </Typography>

          <Typography variant="body1" maxWidth={sm ? 250 : 320} align="center" fontSize={sm ? 12 : 15}>
            Check your inbox for an email from us and press the link to complete your signup process
          </Typography>

          <UserPageFooter footerType="login" />

        </Box>
      </DialogContent>
    </Dialog>
  )

  if (isDone) return <PleaseCheckEmail />

  return (
    <Dialog open PaperProps={{ sx: { borderRadius: "60px" } }} BackdropProps={{ sx: { transition: "none !important" }}} fullWidth>
      <DialogContent>
        <Formik
          initialValues={{ email: initialEmail }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={
            Yup.object().shape({
              email: Yup.string()
                .email("Please enter a valid email address")
                .required("Please enter a valid email address")
            })}
          onSubmit={onSubmit}
        >
          {({ errors, setValues, values }) => (
            <Form>
              <Disabled when={isSaving}>
                <Box width={1} display="flex" justifyContent="center" alignItems="center" flexDirection="column">

                  <PageLogo />

                  <Typography variant="h6" width={1} align="center" fontWeight="700" mt={2} fontSize={sm ? 15 : 20}>
                    Create your free account
                  </Typography>

                  <Typography variant="body1" maxWidth={sm ? 250 : 320} align="center" fontSize={sm ? 12 : 15}>
                    We're glad that you like HypeIndex <br />
                    To keep your account secured, we will need to create your free account and verify your email
                  </Typography>

                  <Box mt={sm ? 1 : 2} />

                  <AppTextField
                    value={values.email}
                    onChange={(email) => setValues({ email })}
                    label="Email"
                    placeholder="Enter your Email address"
                    error={errors.email as string}
                  />

                  <AppButton showLoader={isSaving}>
                    Signup
                  </AppButton>

                  <UserPageFooter footerType="login" />

                </Box>
              </Disabled>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
