import React from "react"
import { Typography } from "@mui/material"
import capitalize from "@mui/utils/capitalize"
import Box from "@mui/material/Box"

export const TextBadge = ({ text, color, size }) => {
  return (
    <Box color={color} sx={theme => ({
      "&": {
        background: (color === "default" ? theme.palette.text.disabled : color),
        padding: 0.2,
        pt: size === "small" ? 0.2 : 0.4,
        px: size === "small" ? 0.5 : 1,
        color: "white",
        width: size === "small" ? "8px" : "fit-content",
        visibility: !text && "hidden",
        borderRadius: 6,
        textAlign:  size === "small" ? "center" : undefined
      }
    })}>
      {size === "small" ? (
        <Typography variant="body2" fontSize={8}>
          {capitalize(text.charAt(0))}
        </Typography>
      ) : (
        <Typography variant="body2" fontSize={11}>
          {capitalize(text)}
        </Typography>
      )}
    </Box>
  )
}
