import React, { MouseEventHandler } from "react"
import { useTheme } from "@mui/material/styles"
import { Box, Tooltip } from "@mui/material"

interface IProps {
  icon: string
  activeIcon: string
  isActive: boolean
  onClick: MouseEventHandler
  alt: string
  tooltip: string
  width: number
}

export const AppLeftMenuButton = (props: IProps): JSX.Element => {
  const theme = useTheme()

  return (
    <Box
      className={props.isActive && "active"}
      onClick={props.onClick}
      display="flex"
      padding={window.innerWidth < 420 || window.innerHeight < 420 ? 2 : 3}
      sx={[
        (theme) => ({
          cursor: "pointer",
          "&:hover > img, &.active img": {
            filter: "drop-shadow(0 0 10px #4a8afe)"
          },
        }),
      ]}
    >
      <Tooltip title={props.tooltip}>
        <Box>
          <img src={props.isActive ? props.activeIcon : props.icon} alt={props.alt} title={props.alt} width={props.width} />
        </Box>
      </Tooltip>
    </Box>
  )
}
