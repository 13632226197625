import React from "react"
import moment from "moment"
import { useTheme } from "@mui/material/styles"
import { Box, Divider, Typography } from "@mui/material"
import { useState } from "react"
import MainStore from "stores/main-store"
import InfoIcon from "@mui/icons-material/Info"
import { observer } from "mobx-react"

export const HypeChartLegend = observer(({ dataPoint }): JSX.Element => {
  const theme = useTheme()
  const [ mainStore ] = useState(() => MainStore)

  if (!dataPoint) return null

  const Row = ({children, ...props}) =>
    <Box display="flex" flexDirection="row" width={1} height={20} {...props}>{children}</Box>

  const CircleCell = ({color}) =>
    <Box width={20} p="1px" display="flex" justifyContent="center" pr={1}>
      <Box width={10} height={10} borderRadius="50%" bgcolor={color} />
    </Box>

  const PipeCell = ({color}) =>
    <Box width={20} pl="1px" display="flex" justifyContent="center" pr={1}>
      <Box width={4} height={10} borderRadius="4px" bgcolor={color} />
    </Box>

  const TextCell = ({children, ...props}) =>
    <Typography variant="body2" color="text.secondary" fontSize={11} width={65} {...props}>{children}</Typography>

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={170}
      position="absolute"
      left={65}
      top={35}
      bgcolor="background.default"
      p={1}
      zIndex={100}
      borderRadius={5}
      sx={{ pointerEvents: "none" }}
    >
      <Row>
        <Typography variant="body2" color="text.secondary" fontSize={12} pl="4px">
          {moment(dataPoint.date).format("MMM DD, YYYY")}
        </Typography>
      </Row>
      <Row>
        <CircleCell color="#8daef5" />
        <TextCell>USD</TextCell>
        <TextCell fontWeight="bold">$ {Intl.NumberFormat("en-US").format(dataPoint.price || 0)}</TextCell>
      </Row>
      <Row>
        <CircleCell color="#ee8ad9" />
        <TextCell>HYPDX</TextCell>
        <TextCell fontWeight="bold">{Intl.NumberFormat("en-US").format(dataPoint.hype || 0)}</TextCell>
      </Row>
      <Row>
        <PipeCell color="#4c8eff" />
        <TextCell>VOL</TextCell>
        <TextCell fontWeight="bold">{Intl.NumberFormat("en-US").format(dataPoint.volume || 0)}</TextCell>
      </Row>
      {/*<Divider/>*/}
      {/*<Row>*/}
      {/*  <Box display="flex" alignItems="center">*/}
      {/*    <Box width={20} ml={0.4} mr={0.6}/>*/}
      {/*    <TextCell>HYPE</TextCell>*/}
      {/*    <TextCell fontWeight="bold">{Intl.NumberFormat("en-US").format(asset?.hype || 0)}</TextCell>*/}
      {/*  </Box>*/}
      {/*</Row>*/}
      {mainStore.currentHypeChartSelection && (
        <Row>
          <Box display="flex" alignItems="center">
            <InfoIcon sx={(theme) => ({ color: theme.palette.text.secondary, fontSize: 15, paddingLeft: "1.5px", paddingRight: "7.5px" })} />
            <TextCell width={1} fontWeight="bold" pt={0.5}>Click to unlock cursor</TextCell>
          </Box>
        </Row>
      )}
    </Box>
  )
})
