import React, { useState } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { App } from "./components/app"
import { SignUpPage } from "./pages/user/signup"
import { FinishSignUpPage } from "./pages/user/finish-signup"
import { LoginPage } from "./pages/user/login"
import { ResetPasswordPage } from "./pages/user/reset-password"
import { NewPasswordPage } from "./pages/user/new-password"
import { AppLayout } from "./components/app-layout/app-layout"
import { LeaderboardPage } from "./pages/leaderboard/leaderboard"
import { HeatmapPage } from "./pages/heatmap/heatmap"
import { HypeChartPage } from "./pages/hype-chart/hype-chart"
import { ArticlePage } from "./pages/article/article"
import { ArticleFeedPage } from "./pages/article-feed/article-feed"
import { AiReportPage } from "./pages/ai-report/ai-report"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { hot } from 'react-hot-loader/root'
import { observer } from "mobx-react"
import MainStore from "./stores/main-store"

const darkTheme = createTheme({
  typography: {
    fontFamily: [ "Helvetica", "Arial", "sans-serif" ].join(",")
  },
  palette: {
    mode: "dark",
    background: {
      paper: "#15181a",
      default: "#282a2e",
    },
    primary: {
      main: '#4c8eff',
    },
    secondary: {
      main: '#d260ec',
    },
    success: {
      main: '#2bb596',
    },
    error: {
      main: '#e3507a',
    },
    text: {
      primary: '#ffffff',
      secondary: '#d4d4d4',
      disabled: '#9492ae',
    }
  },
  breakpoints: {
    values: {
      xs: 360,
      sm: 450,
      md: 640,
      lg: 1024,
      xl: 1200
    }
  }
})

const lightTheme = createTheme({
  typography: {
    fontFamily: [ "Helvetica", "Arial", "sans-serif" ].join(",")
  },
  palette: {
    mode: "light",
    background: {
      paper: "#fafafd",
      default: "#f0eef7",
    },
    primary: {
      main: '#4c8eff',
    },
    secondary: {
      main: '#d260ec',
    },
    success: {
      main: '#2bb596',
    },
    error: {
      main: '#e3507a',
    },
    text: {
      primary: '#4c4c66',
      secondary: '#6f6c99',
      disabled: '#9492ae',
    }
  },
  breakpoints: {
    values: {
      xs: 360,
      sm: 450,
      md: 640,
      lg: 1024,
      xl: 1200
    }
  }
})

export const RootContainer = observer(() => {
  const [ mainStore ] = useState(() => MainStore)

  return (
    <ThemeProvider theme={mainStore.darkMode ? darkTheme : lightTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="signup" element={<SignUpPage />} />
            <Route path="finish-signup" element={<FinishSignUpPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />
            <Route path="new-password" element={<NewPasswordPage />} />
            <Route path="/" element={<AppLayout />}>
              <Route path="/" element={<LeaderboardPage />} />
              <Route path="leaderboard" element={<LeaderboardPage />} />
              <Route path="heatmap" element={<HeatmapPage />} />
              <Route path="hype/:assetId" element={<HypeChartPage />} />
              <Route path="article/:assetId" element={<ArticlePage />} />
              <Route path="article/:assetId/:articleId" element={<ArticlePage />} />
              <Route path="article-feed" element={<ArticleFeedPage />} />
              <Route path="ai-report" element={<AiReportPage />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
})

export default hot(RootContainer)
