import { AlertColor } from "@mui/material/Alert/Alert"
import { SnackbarOrigin } from "@mui/material/Snackbar/Snackbar"

export interface IAsset {
  id: number
  mentions: number
  name: string
  symbol: string
  exchange: string,
  isPro: boolean
}

export interface ILeaderboardAsset {
  id: number
  name: string
  symbol: string
  hype: number
  sentiment: number
  sentimentString: string
  price: number
  exchange: string
  position: number
  positionChange: string
  isPro: boolean
}

export interface IArticle {
  id: number
  createdAt: string
  datetime: string
  headline: string
  sentiment: number
  sentimentString: string
  textSource: string
  textSourceId: number
  textUrl: string
  timestamp: number
  updatedAt: string
  url: string
}

export interface IArticleFeedItem {
  assets: [{name: string, symbol: string, exchange: string}]
  id: number
  headline: string
  url: string
  datetime: string
  sentiment: number
  sentimentString: string
  source: string
  isNew: boolean,
  symbolsJoined: string
  exchangesJoined: string
}

export interface IArticleFeedMetadata {
  updatedAt: number
  updateIntervalMs: number
}

export interface IArticleDateGroup {
  [key: string]: IArticle[]
}

export interface IAssetChartDataItem {
  datetime: string
  hype: number
  hypeRollingAvgBig: number
  hypeRollingAvgSmall: number
  hypesInWindowBig: number[]
  hypesInWindowSmall: number[]
  price: string
  volume: number
}

export interface IAssetMetaData {
  hypeEnd: number
  hypeHigh: number
  hypeLow: number
  hypePercentIncrease: number
  hypeStart: number
  priceEnd: number
  priceHigh: number
  priceLow: number
  pricePercentIncrease: number
  priceStart: number
}

export interface IAssetPriceData {
  datetime: string
  price: number
}

export interface IAssetData {
  id: number
  data: IAssetChartDataItem[]
  meta: IAssetMetaData
  priceData: IAssetPriceData[]
}

export interface IToast {
  message?: string
  type?: AlertColor
  open?: boolean
  position?: SnackbarOrigin
}

export const ExchangeTypesMap: { [key: string]: string } = {
  "New York Stock Exchange": "NYSE",
  "Nasdaq Global Select": "NASDAQ",
  "NASDAQ Capital Market": "NASDAQ",
  "CRYPTO": "CRYPTO"
}
