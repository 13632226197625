export function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min
}

export function getRandomString(length) {
  let result           = ""
  let characters       = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let charactersLength = characters.length
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function parseFirebaseErrorMessage(errorMessage: string) {
  try {
    let origin = errorMessage.replace("Firebase: Error ", "Firebase: ").split("Firebase: ")
    let message = ""
    message = origin[1].split("(auth/")[0].trim()
    if (!message) {
      message = origin[1].split("(auth/")[1].split(")")[0].replaceAll("-", " ")
    }
    return message
  } catch (ex) {}
}

export function sortByField(arrayOfObjects, fieldName) {
  if (!arrayOfObjects) return

  const compare = (a, b) => {
    if (a[fieldName] < b[fieldName]) {
      return -1
    }
    if (a[fieldName] > b[fieldName]) {
      return 1
    }
    return 0
  }
  return arrayOfObjects.slice().sort(compare)
}
