import React from "react"
import { useEffect, useLayoutEffect, useState } from "react"
import Box from "@mui/material/Box"
import { observer } from "mobx-react"
import { useNavigate } from "react-router-dom"
import * as am5 from "@amcharts/amcharts5"
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy"
import am5themes_Material from "@amcharts/amcharts5/themes/Material"
import MainStore from "stores/main-store"
import UserStore from "stores/user-store"
import { IAsset } from "../../stores/main-store.types"
import { useTheme } from "@mui/material/styles"

export const HeatmapPage = observer((): JSX.Element => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [ mainStore ] = useState(() => MainStore)
  const [ userStore ] = useState(() => UserStore)
  const [ widthSetting, setWidthSetting ] = useState("100%")

  const assetTypeFilter = (asset: IAsset) => {
    if (mainStore.assetType === "all") return true
    if (mainStore.assetType === "crypto") return asset.exchange === "CRYPTO"
    return asset.exchange !== "CRYPTO"
  }

  useEffect(() => mainStore.getLeadboard(), [])
  useEffect(() => {
    if (!mainStore.isMobile) {
      setWidthSetting("calc(100% - 50px)")
      setTimeout(() => setWidthSetting("100%"), 50)
    }
  }, [ mainStore.isMobile ])

  useLayoutEffect(() => {
    const leaderboardAssets = mainStore.leaderboardAssets.filter(assetTypeFilter)

    if (!leaderboardAssets.length) return

    let root = am5.Root.new("chartdiv")

    root.setThemes([
      am5themes_Material.new(root)
    ])

    var container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.horizontalLayout,
        background: am5.Rectangle.new(root, {
          stroke: am5.color(theme.palette.background.paper),
          strokeWidth: 0,
          fill: am5.color(theme.palette.background.paper),
          fillOpacity: 1
        })
      })
    )

    var series = container.children.push(
      am5hierarchy.Treemap.new(root, {
        downDepth: 2,
        upDepth: 0,
        initialDepth: 2,
        valueField: "value",
        categoryField: "name",
        childDataField: "children",
        nodePaddingOuter: 40,
        nodePaddingInner: 15,
        // paddingLeft: 20,
        // paddingRight: 20,
        layoutAlgorithm: "binary"

      })
    )

    series.rectangles.template.setAll({
      // minWidth: 50,
      // fill: am5.color(0xffffff),
      // shadowColor: am5.color(0xb30000),
      cursorOverStyle: "pointer",
      shadowOffsetY: 5,
      shadowOffsetX: 5,
      shadowBlur: 12,
      shadowOpacity: 0.15,
      strokeWidth: 3,
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10
    })

    series.rectangles.template.adapters.add("fill", function (fill, target) {
      if (!target.dataItem.get("value")) return null
      if (target.isHover()) return am5.color(theme.palette.background.paper)
      return am5.color(theme.palette.background.default)
    })

    series.rectangles.template.adapters.add("strokeGradient", function (fill, target) {
      const sentimentString = target.dataItem.dataContext.sentimentString
      let color = am5.color(0x9492ac)

      if (!sentimentString) return

      if (sentimentString.includes("positive")) {
        color = am5.color(0x5ab297)
      } else if (sentimentString.includes("negative")) {
        color = am5.color(0xd25a7a)
      }

      return am5.LinearGradient.new(root, { stops: [{ color }, { color }] })


      // let hype = target.dataItem.dataContext.hype
      //
      // if (hype === undefined || hype === null) return null
      // // if (target.isHover()) return null

      // if (hype >= 0) {
      //   return am5.LinearGradient.new(root, {
      //     stops: [{
      //       color: am5.color(0xa1f5f2)
      //     }, {
      //       color: am5.color(0x61c2cf)
      //     }, {
      //       color: am5.color(0x63c178)
      //     }]
      //   })
      // } else {
      //   return am5.LinearGradient.new(root, {
      //     stops: [ {
      //       color: am5.color(0xeb949e)
      //     }, {
      //       color: am5.color(0xcd79c8)
      //     }, {
      //       color: am5.color(0x995fb8)
      //     } ]
      //   })
      // }
    })

    series.rectangles.template.adapters.add("shadowColor", function (fill, target) {
      if (!target.dataItem.get("value")) return null
      return am5.color("rgba(12, 10, 32)")
    })

    series.rectangles.template.states.create("hover", {
      // strokeGradient: am5.LinearGradient.new(root, {
      //   stops: [ {
      //     color: am5.color(0xee6779)
      //   }, {
      //     color: am5.color(0x61c2cf)
      //   } ]
      // }),
      fill: null
    })

    series.rectangles.template.events.on("click", function (ev) {
      const assetId = ev.target._parent._dataItem.dataContext.id
      if (assetId) {
        navigate(`/hype/${assetId}`)
      }
      // console.log("Clicked on a column", ev.target._parent._dataItem.dataContext)
    })

    // series.rectangles.template.events.on("pointerover", function(ev) {
    //   console.log("Hovered on a column", ev.target);
    //   ev.target.setAll({ strokeGradient: null })
    // });
    //
    // series.rectangles.template.events.on("pointerout", function(ev) {
    //   console.log("! Hovered on a column", ev.target);
    //   ev.target.setAll({ strokeGradient: null })
    // });

    series.nodes.template.set("tooltipText", "")

    // series.rectangles.template.adapters.add("tooltip", function (fill, target) {
    //   if (target.dataItem.get("value")) return null
    // })

    series.labels.template.setAll({
      fontSize: 20,
      fill: am5.color(theme.palette.text.primary),
      textAlign: "center",
      text: "{name}\n[#858585][fontSize: 13px]({exchange})[/]\n[fontSize: 13px]{hype}%[/][/]"
    })

    series.labels.template.adapters.add("text", function (fill, target) {
      if (!target.dataItem || !target.dataItem.get("value")) return null
      return fill
    })

    // series.rectangles.template.set("tooltipText", "{name} | [bold]{sum}[/]")

    // series.rectangles.template.set("strokeGradient", am5.LinearGradient.new(root, {
    //   stops: [ {
    //     color: am5.color(0x9de0e9)
    //   }, {
    //     color: am5.color(0x61c2cf)
    //   }, {
    //     color: am5.color(0x63c178)
    //   } ]
    // }))

    // series.data.processor = am5.DataProcessor.new(root, {
    //   colorFields: ["color"]
    // })

    const data = [ {
      root: true,
      children: leaderboardAssets.map(a => ({
        ...a,
        name: a.isPro && !userStore.currentUserData?.isProUser ? "?" : a.symbol,
        value: 20 + (parseFloat(a.hype) < 0 ? 0 - parseFloat(a.hype) : parseFloat(a.hype)),
        hype: parseFloat(a.hype),
        sentimentString: a.sentimentString,
        exchange: a.exchange
      }))
    } ]

    series.data.setAll(data)
    series.set("selectedDataItem", series.dataItems[0])
    root.current = root

    return () => {
      root.dispose()
    }
  }, [ mainStore.leaderboardAssets, mainStore.assetType, mainStore.darkMode ])


  return (
    <Box overflow="auto" height={1} pb={mainStore.isMobile ? 10 : 0}>
      <div id="chartdiv" style={{ width: widthSetting, height: "3000px", cursor: "pointer" }} />
    </Box>
  )
})
