import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { observer } from "mobx-react"
import { IArticleFeedItem, IAsset, ILeaderboardAsset } from "stores/main-store.types"
import MainStore from "stores/main-store"
import { EnhancedTable, IHeadCell, ITableDataItem } from "components/common/enhanced-table"
import { Link } from "@mui/material"
import moment from "moment/moment"
import { MobileDialog } from "./mobile-dialog/mobile-dialog"
import { IScrollData, useScrollPagination } from "components/common/hooks/useScrollPagination"
import { SentimentAnalysisBadge } from "components/common/sentiment-analysis-badge"
import userStore from "../../stores/user-store"

const headCells: IHeadCell[] = [
  {
    id: "datetime",
    align: "left",
    label: "Time",
    width: "10%",
    numeric: false,
    disablePadding: true,
    displayOnMobile: false,
    tooltip: true,
    renderValue: (row: IArticleFeedItem) => {
      try {
        return moment(row.datetime).fromNow()
      } catch (ex) {}
    }
  }, {
    id: "symbolsJoined",
    align: "left",
    label: "symbols",
    width: "10%",
    numeric: false,
    disablePadding: false,
    displayOnMobile: false,
    tooltip: true
  }, {
    id: "exchangesJoined",
    align: "left",
    label: "Exchange",
    width: "12%",
    numeric: false,
    disablePadding: false,
    displayOnMobile: false,
    tooltip: true
  }, {
    id: "source",
    align: "left",
    numeric: false,
    disablePadding: false,
    displayOnMobile: false,
    tooltip: true,
    label: "Source",
    width: "10%"
  }, {
    id: "headline",
    label: "Article",
    align: "left",
    width: "53%",
    numeric: false,
    disablePadding: false,
    tooltip: true,
    renderValue: (row: IArticleFeedItem) => (
      window.mainStore && window.mainStore.isMobile ? (
        row.headline
      ) : (
        <Link href={row.url} target="_blank" referrerPolicy="no-referrer">{row.headline}</Link>
      )
    )
  }, {
    id: "sentimentString",
    numeric: true,
    disablePadding: false,
    label: "Sentiment",
    displayOnMobile: true,
    align: "center",
    overflow: "visible",
    width: "5%",
    renderValue: (row: ILeaderboardAsset) => <SentimentAnalysisBadge sentimentString={row.sentimentString} />
  }
]


interface IArticleFeedProps {
  articleFeedItems: IArticleFeedItem[]
  scrollData: IScrollData
}
export const ArticleFeedTable = observer(({ articleFeedItems, scrollData }: IArticleFeedProps) => {
  const [ mainStore ] = useState(() => MainStore)
  const [ mobileDialogArticle, setMobileDialogArticle ] = useState<IArticleFeedItem>(undefined)
  const [ renderedItems ] = useScrollPagination(articleFeedItems, scrollData)

  const assetTypeFilter = (item: IArticleFeedItem) => {
    const assetsExchanges = item.assets.map(a => a.exchange)
    // if (mainStore.assetType === "all") return true
    if (mainStore.assetType === "crypto") return assetsExchanges.includes("CRYPTO")
    if (mainStore.assetType === "favorites") {
      const assets = mainStore.allAssets
        .filter(asset => item.assets.map(a => a.symbol).includes(asset.symbol))
        .map(asset => asset.id)
      return userStore.currentUserData?.favoriteAssets.some(faId => assets.includes(faId))
    }
    return !assetsExchanges.includes("CRYPTO")
  }

  const onTableRowClick = (row: ITableDataItem) => {
    if (!mainStore.isMobile) return
    setMobileDialogArticle(row as IArticleFeedItem)
  }

  return (
    <Box width={1} overflow="visible" mb={4}>
      <EnhancedTable
        tableCells={headCells}
        tableData={mainStore.isMobile ? renderedItems : articleFeedItems}
        filter={assetTypeFilter}
        orderByDefault="datetime|desc"
        onRowClick={onTableRowClick}
      />
      {/*{loading && <CircularProgress />}*/}
      <MobileDialog articleFeedItem={mobileDialogArticle} onClose={() => setMobileDialogArticle(undefined)} />
    </Box>
  )
})
