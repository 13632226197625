import React from "react"
import { useEffect, useState } from "react"
import { Box, Button, Switch, Tooltip, Typography } from "@mui/material"
import { AppTopBarDropDown } from "../app-top-bar-dropdown"
import { observer } from "mobx-react"
import MainStore from "stores/main-store"
import moment from "moment"
import { TopNotificationsButton } from "./top-notifications-button"
import { TopUserButton } from "./top-user-button"
import { useTheme } from "@mui/material/styles"

const IconLogoBlack = require("assets/logo/hypeindex-icon-logo-black.svg")
const TextLogoBlack = require("assets/logo/hypeindex-text-black.svg")
const IconLogoWhite = require("assets/logo/hypeindex-icon-logo-white.svg")
const TextLogoWhite = require("assets/logo/hypeindex-text-white.svg")

interface IProps {
  isSmallAndWideScreen: boolean
}

export const AppTopBar = observer(({ isSmallAndWideScreen }: IProps): JSX.Element => {
  let lastRefreshTimestampMs: number
  const theme = useTheme()
  const [ mainStore ] = useState(() => MainStore)

  const getUpdatedString = () =>
    `${moment(lastRefreshTimestampMs).fromNow()} (${moment(lastRefreshTimestampMs).format("dddd MMMM D, YYYY [at] hh:mm a")})`
  const [ updatedString, setUpdatedString ] = useState(getUpdatedString())

  useEffect(() => {
    lastRefreshTimestampMs = Date.now()
    setInterval(() => setUpdatedString(getUpdatedString()), 10000)
  }, [])



  return (
    <Box
      display="flex"
      flexDirection={mainStore.isMobile ? "column" : "row"}
      alignItems="center"
      justifyContent="space-between"
      px={mainStore.isMobile ? 0 : 2}
    >
      {mainStore.isMobile && !isSmallAndWideScreen && (
        <Box width={100} display="flex" justifyContent="space-between" padding={1}>
          {mainStore.darkMode ? (
            <>
              <img src={IconLogoWhite} alt="logo" width={20} />
              <img src={TextLogoWhite} alt="logo" width={70} />
            </>
          ) : (
            <>
              <img src={IconLogoBlack} alt="logo" width={20} />
              <img src={TextLogoBlack} alt="logo" width={70} />
            </>
          )}
        </Box>
      )}
      <AppTopBarDropDown />
      <Box display="flex" alignItems="center">
        <Typography variant="body2" color="text.secondary" px={4} py={1}>
          Updated {updatedString}
        </Typography>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Box display="flex" alignItems="center">
            <TopNotificationsButton />
            <TopUserButton />
          </Box>
          {mainStore.isMobile && (
            <Box display="flex" alignItems="center" width={120}>
              <Typography variant="body2" textTransform="uppercase" color={theme.palette.text.disabled} fontSize={10}>
                Dark Mode
              </Typography>
              <Switch checked={mainStore.darkMode} onChange={(e) => mainStore.setDarkMode(e.target.checked)} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
})
