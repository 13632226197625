import { makeAutoObservable } from "mobx"
import { globalInstance } from "utils/singleton"
import NetworkService from "services/network-service"
import {
  ExchangeTypesMap,
  IArticle,
  IArticleDateGroup, IArticleFeedItem, IArticleFeedMetadata,
  IAsset,
  IAssetData,
  ILeaderboardAsset, IToast
} from "./main-store.types"
import { sortByField } from "utils/helpers"
import moment from "moment"

// const BASE_API_URL = "https://serve.hypeindex.io/frontend"
// const CACHED_API_URL = "http://localhost:36912/frontend"
const CACHED_API_URL = location.href.includes("http://localhost") ? "http://localhost:36912/frontend" : "https://storage.googleapis.com/cdn-data-hypeindex/frontend"
const BASE_API_URL = "/data"

class MainStore {
  constructor() {
    makeAutoObservable(this)
    const dMode = localStorage.getItem("dark_mode")
    this.setDarkMode(JSON.parse(dMode))
  }

  public loading: boolean = false
  public assetLoading: boolean = false
  public appTitle: string = ""
  public isMobile: boolean = false
  public deviceWidth: string
  public toast: IToast = {}

  public darkMode: boolean = false
  public leaderboardAssets: ILeaderboardAsset[] = []
  public articleFeedItems: IArticleFeedItem[] = []
  public articleFeedMetadata: IArticleFeedMetadata = { updatedAt: 1669207781785, updateIntervalMs: 3*60*60*1000 }
  public allAssets: IAsset[] = []
  public assetType: string = "stocks"
  public currentAssetData: IAssetData = undefined
  public currentAssetArticles: IArticleDateGroup = undefined
  public currentAssetArticlesFlatten: IArticle[] = []
  public currentArticle: IArticle = undefined
  public currentArticleText: string = undefined
  public currentHypeChartSelection = undefined
  public currentArticleListDateValue = undefined

  public async getLeadboard() {
    if (this.leaderboardAssets.length) return
    if (this.loading) return

    try {
      this.setLoading(true)

      const leadboardRes = await NetworkService.fetch(`${CACHED_API_URL}/leaderboard`)
      // const leadboardRes = await NetworkService.fetch(`http://localhost:36912/data/leaderboard.json`)
      const processedLeaderboardData = leadboardRes.cryptos ? [ ...leadboardRes.stocks, ...leadboardRes.cryptos ] : leadboardRes
      await this.getAllAssets()

      const response = processedLeaderboardData
        .filter(lbRow => this.allAssets.some(a => a.id === lbRow.id))
        .map(lbRow => {
          const asset: IAsset = this.allAssets.find(a => a.id === lbRow.id)
          asset.isPro = lbRow.hype >= 150
          return {
            ...lbRow,
            ...asset,
            ...{ exchange: ExchangeTypesMap[asset.exchange] || asset.exchange }
          }
        })

      const sorted = [ ...sortByField(response, "position") ].reverse()
      this.leaderboardAssets = sorted
    }catch (ex) {
      // throw ex
    } finally {
      this.setLoading(false)
    }
  }

  public setLeaderboardAssets(assets: ILeaderboardAsset[]) {
    this.leaderboardAssets = assets
  }

  private setAssetLoading(value) {
    this.assetLoading = value
  }

  public async getAllAssets() {
    if (this.allAssets.length) return
    if (this.assetLoading) return

    try {
      this.setAssetLoading(true)
      const response = await NetworkService.fetch(`${CACHED_API_URL}/assets_list`)
      const sorted = sortByField(response, "symbol")
      this.setAllAssets(sorted)
    } finally {
      this.setAssetLoading(false)
    }
  }

  public async getAssetData(assetId) {
    if (this.currentAssetData?.id === assetId) return

    await this.getLeadboard() // for isPro on asset

    this.setCurrentHypeChartSelection(undefined)
    this.setCurrentAssetData(undefined)

    const response = await NetworkService.fetch(`${CACHED_API_URL}/${assetId}/asset`)
    const asset: IAsset = this.allAssets.find(a => a.id.toString() === assetId)

    this.setCurrentAssetData({
      id: assetId,
      asset: asset,
      data: response.data.map(a => ({ ...a, price: parseFloat(a.price || 0) })),
      priceData: response.priceData.map(a => ({ ...a, price: parseFloat(a.price || 0) })),
      meta: {
        ...response.meta,
        priceEnd: parseFloat(response.meta.priceEnd || 0),
        priceHigh: parseFloat(response.meta.priceEnd || 0),
        priceLow: parseFloat(response.meta.priceEnd || 0),
        priceStart: parseFloat(response.meta.priceEnd || 0)
      }
    })
  }

  public async getAssetArticles(assetId) {
    if (this.currentAssetArticles?.id === assetId) return

    this.currentAssetArticles = {}

    const assetArticles = await NetworkService.fetch(`${CACHED_API_URL}/${assetId}/asset_articles`)

    let flatten = []
    Object.keys(assetArticles).forEach(key => {
      flatten = flatten.concat(assetArticles[key])
    })

    this.currentAssetArticles = { ...assetArticles, id: assetId }
    this.currentAssetArticlesFlatten = flatten
  }

  public async getArticle(assetId, articleId) {
    if (!this.currentAssetArticles) {
      await this.getAssetArticles(assetId)
    }

    const article = this.currentAssetArticlesFlatten.find(a => a.id.toString() === articleId.toString())

    if (article) {
      this.currentArticle = article
    } else {
      throw `No article found with articleId: ${articleId}`
    }
  }

  public async getAssetArticleText(assetId, articleId) {
    await this.getArticle(assetId, articleId)
    return await NetworkService.fetchText(this.currentArticle.textUrl)
  }
  public async getArticleFeed() {
    await this.getAllAssets()
    const response = await NetworkService.fetch(`${CACHED_API_URL}/article_feed`)

    response.articles.forEach(ar => {
      try {
        ar.assets.forEach(a =>
          a.exchange = ExchangeTypesMap[this.allAssets.find(as => as.symbol === a.symbol).exchange])
      } catch {}
      try {
        ar.exchangesJoined = ar.assets
          .filter(a => a.exchange)
          .map(a => a.exchange)
          .filter((a, i, s) => s.indexOf(a) === i) // => unique
          .join(", ")
      } catch {}
      try {
        ar.symbolsJoined = ar.assets.map(a => a.symbol).join(", ")
      } catch {}
      try {
        ar.datetime = ar.datetime.replace("  ", " ")
        ar.datetime = moment.utc(ar.datetime, "YYYY-MM-DD HH:mma").toDate().getTime()
      } catch {}
    })

    this.articleFeedItems = response.articles.sort((a, b) => a.datetime - b.datetime).reverse()
    this.articleFeedMetadata = {
      updatedAt: response.updatedAt,
      updateIntervalMs: response.interval * 1000
    }
    // this.articleFeedItems = this.articleFeedItems.slice(0, 235)
    return this.articleFeedItems
  }

  public async getAssetAiReport(assetId) {
    if (location.href.includes("http://localhost")) {
      return await NetworkService.fetchText(`${CACHED_API_URL}/ai/${assetId}`)
    } else {
      return await NetworkService.fetchText(`https://serve.hypeindex.io/frontend/ai/${assetId}`)
    }
  }

  setAllAssets(allAssets) {
    this.allAssets = allAssets
  }
  setArticleFeedItems(data) {
    this.articleFeedItems = data
  }

  setCurrentAssetData(data) {
    this.currentAssetData = data
  }

  setLoading(status: boolean) {
    this.loading = status
  }

  setAppTitle(value: string) {
    this.appTitle = value
  }

  // deprecated
  setIsMobile(value: boolean) {
    this.isMobile = value
  }

  setDeviceWidth(value: string) {
    this.deviceWidth = value
  }

  setCurrentHypeChartSelection(data) {
    this.currentHypeChartSelection = data
  }

  setCurrentArticleListDateValue(dateValue) {
    this.currentArticleListDateValue = dateValue
  }

  setAssetType(value) {
    this.assetType = value
  }

  openToast(toast: IToast) {
    this.toast = { ...toast, open: true }
  }

  closeToast() {
    this.toast = { ...this.toast, open: false }
  }

  setDarkMode(value: boolean) {
    this.darkMode = !!value
    localStorage.setItem("dark_mode", this.darkMode.toString())
  }
}

export default globalInstance("mainStore", MainStore)
