import React, { useEffect, useState } from "react"
import moment from "moment"
import AlertsStore from "stores/alerts-store"
import { Badge, Box, Button, List, Menu, MenuItem, Tooltip, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { SettingsOutlined } from "@mui/icons-material"
import { observer } from "mobx-react"
import { AlertTypes } from "../../../stores/alerts-store.types"
import userStore from "../../../stores/user-store"
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined"
import { useTheme } from "@mui/material/styles"
import MainStore from "../../../stores/main-store"

const NotificationsBlackIcon = require("assets/icons/notifications-outline-black.svg")
const NotificationsWhiteIcon = require("assets/icons/notifications-outline-white.svg")
const AddNotificationBlackIcon = require("assets/icons/add-notification-black.svg")
const AddNotificationWhiteIcon = require("assets/icons/add-notification-white.svg")

export const TopNotificationsButton = observer(() => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { assetId } = useParams()
  const [ alertsStore ] = useState(() => AlertsStore)
  const [ mainStore ] = useState(() => MainStore)
  const [ anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const unreadCount = AlertsStore.allUserNotifications.filter(noti => !noti.isRead).length

  useEffect(() => {
    alertsStore.getAllUserNotifications()
    setInterval(() => alertsStore.getAllUserNotifications(), 30000)
  }, [])

  const onButtonClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const onNotificationClick = (assetId, notificationId) => {
    alertsStore.setNotificationAsRead({notification: notificationId})
    navigate(`/hype/${assetId}`)
    setAnchorEl(null)
  };

  const onSetupAlertsClick = () => {
    alertsStore.setSetupAlertsPopup({open: true})
    setAnchorEl(null)
  }

  const onAddAlertClick = (assetId) => {
    alertsStore.setAddAlertPopup({open: true, selectedAsset: assetId})
    setAnchorEl(null)
  }

  const onMarkAllAsReadClick = () => {
    alertsStore.setAllNotificationAsRead()
  }

  const NotificationItem = ({notificationData}) => {
    return (
      <MenuItem onClick={() => onNotificationClick(notificationData.alert.asset.id, notificationData.id)} style={{ borderTop: "1px solid lightgray" }}>
        <Box minWidth={250} width={1} p={1} style={{ opacity: notificationData.isRead && 0.5 }}>
          <Box display="flex" flexDirection="row-reverse" mb={1}>
            {!notificationData.isRead && (
              <Box borderRadius="50%" bgcolor="primary.main" width={10} height={10} />
            )}
            <Typography variant="body2" width="100%" textAlign="left" style={{ opacity: 0.5 }}>
              {/*{moment(new Date()).format("YYYY-MM-DD hh:mma")}*/}
              {moment(notificationData.dateTime).fromNow()}
            </Typography>
          </Box>
          <Typography variant="body1">
            <b>{notificationData.alert.asset.symbol}</b> - {notificationData.alert.fieldType} is {AlertTypes[notificationData.alert.alertType].toLowerCase()} {notificationData.alert.testValue} (actual, {notificationData.actualValue})
          </Typography>
        </Box>
      </MenuItem>
    )
  }

  if (!userStore.currentUserData?.firebaseId) return null

  return (
    <>
      <Badge badgeContent={unreadCount} color="primary" sx={theme => ({
        "& .MuiBadge-badge": {
          top: 12, right: 12
        }
      })}>
        <Button
          type="submit"
          size="large"
          fullWidth
          onClick={onButtonClick}
          // disabled={!alertsStore.allUserNotifications.length}
          sx={{
            height: "50px",
            width: "50px",
            borderRadius: "35px",
            background: theme.palette.background.default,
            // boxShadow: "0 10px 50px rgba(0, 0, 0, 0.15)",
            margin: "10px 10px 10px 0",
            minWidth: 0,
            // opacity: !alertsStore.allUserNotifications.length ? 0.3 : 1
          }}
        >
          <img src={mainStore.darkMode ? NotificationsWhiteIcon : NotificationsBlackIcon} alt="logo" width={30} />
        </Button>
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <List sx={{ padding: 0 }}>
          <MenuItem onClick={onSetupAlertsClick}>
            <Box display="flex" alignItems="center">
              <Box p={0.5} height="25px"><SettingsOutlined/></Box>
              <Typography variant="body2" px={1}>Setup Alerts</Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={() => onAddAlertClick(assetId)}>
            <Box display="flex" alignItems="center">
              <Box p={0.5} height="25px"><img src={mainStore.darkMode ? AddNotificationWhiteIcon : AddNotificationBlackIcon} alt="alert" width={18} style={{padding: "2px 3px"}} /></Box>
              <Typography variant="body2" px={1}>Add Alert</Typography>
            </Box>
          </MenuItem>
        </List>
        {!!alertsStore.allUserNotifications.length && (
          <Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" borderBottom="1px solid gray" px={3} py={1} mt={1}>
              <Typography variant="body2" width="100%" textAlign="left" style={{ opacity: 0.5 }} fontWeight="600" textTransform="uppercase">
                Notifications
              </Typography>
              <Tooltip title="Mark all as read">
                <Box sx={{ cursor: "pointer" }} onClick={onMarkAllAsReadClick}>
                  <MarkEmailReadOutlinedIcon />
                </Box>
              </Tooltip>
            </Box>
            <List sx={{ height: "calc(100vh - 225px)", overflowY: "auto", padding: 0 }}>
              {alertsStore.allUserNotifications.slice().reverse().map(noti => (
                <NotificationItem key={noti.id} notificationData={noti} />
              ))}
            </List>
          </Box>
        )}
      </Menu>
    </>
  )
})
