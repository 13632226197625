import React from "react"
import { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import MainStore from "stores/main-store"
import { observer } from "mobx-react"
import { useNavigate } from "react-router-dom"
import { ArticleFeedTable } from "./article-feed-table"
import Typography from "@mui/material/Typography"
import { IArticleFeedItem } from "stores/main-store.types"
import { IScrollData } from "components/common/hooks/useScrollPagination"

export const ArticleFeedPage = observer((): JSX.Element => {
  const navigate = useNavigate()
  const [ mainStore ] = useState(() => MainStore)
  const [ renderedFeedItems, setRenderedFeedItems ] = useState<IArticleFeedItem[]>([])
  const [ scrollData, setScrollData ] = useState<IScrollData>(undefined)

  useEffect(() => { mainStore.getArticleFeed() }, [])

  useEffect(() => {
    if (!mainStore.articleFeedItems.length) return

    const { updateIntervalMs, updatedAt } = mainStore.articleFeedMetadata
    const newItems = [...mainStore.articleFeedItems].filter(a => a.isNew)
    const intervalPerItemMs = updateIntervalMs / newItems.length
    const nowMs = Date.now()
    const feedUpdatedAtMs = new Date(updatedAt).getTime()
    const passedTimeSinceLastUpdateMs = nowMs - feedUpdatedAtMs
    const nextUpdateIntervalDelay = 5 * 60 * 1000 // ==> 5 min

    let nextUpdateTimeoutMs = passedTimeSinceLastUpdateMs + updateIntervalMs + nextUpdateIntervalDelay
    let initialNewItemCount = Math.floor(passedTimeSinceLastUpdateMs / intervalPerItemMs)
    let initialItems = [...mainStore.articleFeedItems].filter(a => !a.isNew)

    if (passedTimeSinceLastUpdateMs - updateIntervalMs > 0) { // means that the update was late :)
      initialNewItemCount = newItems.length
      nextUpdateTimeoutMs = nextUpdateIntervalDelay * 2
    }

    for (let i = 1; i < initialNewItemCount; i++) {
      let item = newItems.pop()
      item.isNew = false
      initialItems.push(item)
    }

    setRenderedFeedItems(initialItems)

    const iv = setInterval(() => {
      if (!newItems.length) { clearInterval(iv); return }
      initialItems = [newItems.pop()].concat(initialItems)
      setRenderedFeedItems(initialItems)
    }, intervalPerItemMs)

    const to = setTimeout(() => mainStore.getArticleFeed(), nextUpdateTimeoutMs)

    return () => {
      clearInterval(iv)
      clearTimeout(to)
    }

  }, [ mainStore.articleFeedItems ])

  const onTableScroll = (e) => {
    if (!mainStore.isMobile) return
    setScrollData({
      scrollTop: e.target.scrollTop,
      scrollHeight: e.target.scrollHeight
    })
  }

  return (
    <Box overflow="auto" height={1} p={mainStore.isMobile ? 2 : 4} pt={0} onScroll={onTableScroll}>
      <Typography fontSize="x-large" color="text.secondary" fontWeight="300" mt={1} p={2}>
        {!mainStore.isMobile && `${new Date().toDateString()} | `} Article feed page
      </Typography>
      <ArticleFeedTable articleFeedItems={renderedFeedItems} scrollData={scrollData} />
    </Box>
  )
})
