import { useEffect, useState } from "react"

export interface IScrollData {
  scrollTop: number
  scrollHeight: number
  findIndex?: number
}

const PAGE_ITEM_COUNT = 50
const SCROLL_THRESHOLD = window.innerHeight <= 420 ? 1000 : 1500

export const useScrollPagination = (inputData: any[], scrollData: IScrollData, findIndex?: number) => {
  const [ renderedItems, setRenderedItems ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const totalPages = Math.ceil(inputData.length / PAGE_ITEM_COUNT)
  const [ page, setPage ] = useState(0)

  useEffect(() => {
    if (!findIndex) return
    setPage(Math.floor(findIndex / PAGE_ITEM_COUNT))
  }, [ findIndex ])

  useEffect(() => {
    if (loading) return
    setTimeout(() => {
      if (!scrollData || scrollData.scrollHeight < SCROLL_THRESHOLD) return
      if (scrollData.scrollTop >= scrollData.scrollHeight - SCROLL_THRESHOLD) {
        if (page + 1 === totalPages) return
        setPage(page + 1)
      } else if (scrollData.scrollTop <= SCROLL_THRESHOLD && page > 0) {
        setPage(page - 1)
      }
    }, 0)
  }, [ scrollData ])

  useEffect(() => {
    setLoading(true)
    // setTimeout(() => {
      if (page === 0) {
        setRenderedItems(inputData.slice((page) * PAGE_ITEM_COUNT, (page) * PAGE_ITEM_COUNT + PAGE_ITEM_COUNT))
        return
      }
      setRenderedItems(inputData.slice((page) * PAGE_ITEM_COUNT - PAGE_ITEM_COUNT, (page) * PAGE_ITEM_COUNT + PAGE_ITEM_COUNT))
    // }, 1000)
  }, [ page ])

  useEffect(() => {
    setRenderedItems(inputData.slice(0, PAGE_ITEM_COUNT))
  }, [ inputData ])

  useEffect(() => {
    setLoading(false)
  }, [ renderedItems ])

  return [ renderedItems, loading ]
}
