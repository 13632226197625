import { FirebaseApp, initializeApp } from "firebase/app"
import { Analytics, getAnalytics } from "firebase/analytics"
import {
  Auth,
  User,
  getAuth,
  createUserWithEmailAndPassword,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  signOut
} from "@firebase/auth"
import { getDatabase, onValue, ref, set } from "@firebase/database"
import { IUser } from "../stores/user-store.types"

const firebaseConfig = {
  apiKey: "AIzaSyDu1KagKwKyD7Zk6H7YvJ_j1Y5UnniBIBw",
  authDomain: "hypeindex-19798.firebaseapp.com",
  projectId: "hypeindex-19798",
  storageBucket: "hypeindex-19798.appspot.com",
  messagingSenderId: "746205175781",
  appId: "1:746205175781:web:e97b8b05330ad23570d90c",
  measurementId: "G-YLZGR0Z3QN",
  databaseURL: "https://hypeindex-19798-default-rtdb.firebaseio.com"
}

export default class FirebaseService {
  public static app: FirebaseApp
  public static auth: Auth
  public static analytics: Analytics
  public static currentUser: User

  public static init() {
    return new Promise(resolve => {
      FirebaseService.app = initializeApp(firebaseConfig)
      FirebaseService.auth = getAuth(FirebaseService.app)
      FirebaseService.analytics = getAnalytics(FirebaseService.app)
      FirebaseService.auth.onAuthStateChanged(user => {
        if (user) {
          FirebaseService.currentUser = user
        }
        resolve(user)
      })
    })
  }

  public static async sendSignInLinkToEmail(email: string) {
    const actionCodeSettings = { url: window.location.origin, handleCodeInApp: true }
    return sendSignInLinkToEmail(FirebaseService.auth, email, actionCodeSettings)
  }

  public static async validateEmailConfirmation(email: string) {
    const isValid = isSignInWithEmailLink(FirebaseService.auth, window.location.href)

    if (!email || !isValid) return Promise.reject()

    return signInWithEmailLink(FirebaseService.auth, email, window.location.href)
  }

  public static async createUserWithEmailAndPassword(email: string, password: string) {
    return createUserWithEmailAndPassword(FirebaseService.auth, email, password)
  }

  public static async signInWithEmailAndPassword(email: string, password: string) {
    return signInWithEmailAndPassword(FirebaseService.auth, email, password)
  }

  public static async sendPasswordResetEmail(email: string) {
    const actionCodeSettings = { url: window.location.origin, handleCodeInApp: true }
    return sendPasswordResetEmail(FirebaseService.auth, email, actionCodeSettings)
  }

  public static async verifyPasswordResetCode(code: string) {
    return verifyPasswordResetCode(FirebaseService.auth, code)
  }

  public static async confirmPasswordReset(code: string, newPassword: string) {
    return confirmPasswordReset(FirebaseService.auth, code, newPassword)
  }

  public static async signOut() {
    return signOut(FirebaseService.auth)
  }

  public static async readUserData(userId) {
    const db = getDatabase()
    return new Promise((resolve) => {
      onValue(ref(db, "/users/" + userId), (snapshot) => {
        resolve({ firebaseId: userId, ...snapshot.val() })
      }, {
        onlyOnce: true
      })
    })
  }

  public static async writeUserData(uid, email, firstName, lastName) {
    const db = getDatabase()
    return set(ref(db, "users/" + uid), {
      email, firstName, lastName, isProUser: false
    })
  }

  public static async updateUserData(userData: IUser) {
    const db = getDatabase()
    const user = { ...userData }

    delete user.firebaseId

    return set(ref(db, "users/" + FirebaseService.currentUser.uid), userData)
  }
}
