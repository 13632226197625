import React, { useState } from "react"
import UserStore from "stores/user-store"
import {
  Box,
  Dialog,
  DialogContent,
  Typography, useMediaQuery
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { PageLogo } from "components/common/page-logo"
import { AppButton } from "components/common/app-controls/app-button"
import { useNavigate } from "react-router-dom"
import { Close } from "@mui/icons-material"
import { DialogTopIconButton } from "components/common/app-controls/dialog-top-icon-button"

export const LoginInfoModal = ({ show, onHide }: { show: boolean, onHide: () => void }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))
  const [ userStore ] = useState(() => UserStore)

  return (
    <Dialog open={show} onClose={onHide} PaperProps={{ sx: { borderRadius: "60px" } }} BackdropProps={{ sx: { transition: "none !important" }}} fullWidth>
      <DialogContent>
        <Box width={1} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Box position="absolute" top={15} right={10}>
            <DialogTopIconButton position="right" tooltipTitle="Close" icon={<Close />} onClick={onHide} />
          </Box>

          <PageLogo />

          <Typography variant="h6" width={1} align="center" fontWeight="700" mt={5} fontSize={sm ? 15 : 20}>
            You are signed in as:
          </Typography>

          <Typography variant="h6" width={1} align="center" fontWeight="100" color="text.secondary" mt={1} fontSize={sm ? 20 : 30}>
            {userStore.currentUserData?.email}
          </Typography>

          <AppButton onClick={() => navigate("login")}>
            Log out
          </AppButton>

          <Box mt={sm ? 2 : 3} sx={{cursor: "pointer"}} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
