import React, { useState } from "react"
import Box from "@mui/material/Box"
import { useTheme } from "@mui/material/styles"
import { Autocomplete, Menu, MenuItem, TextField, useMediaQuery } from "@mui/material"
import { OutlinedTextFieldProps } from "@mui/material/TextField/TextField"

interface IProps extends Omit<OutlinedTextFieldProps, "variant" | "onChange" | "error"> {
  label?: string
  value?: string
  placeholder?: string
  inlineComponent?: JSX.Element
  error?: string
  onChange?: (value: string) => void
  options: {text: string, value: string}[]
  autoComplete?: boolean
  // typingMode?: "none" | "filter" | "free"
}

export const AppSelectField = (props: IProps) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))
  const [ anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [ menuWidth, setMenuWidth ] = useState(100)
  const [ typedText, setTypedText ] = useState("")
  const selectedOptionText = (props.options.find(op => op.value === props.value) || {text: ""}).text
  // const typingMode = props.typingMode || "none"

  const onChange = (value) => {
    props.onChange?.(value)
    setAnchorEl(null)
  }

  const renderTextField = (params?) => {
    return (
      <TextField
        label={props.label}
        // placeholder={props.placeholder}
        helperText={props.error}
        value={typedText || selectedOptionText}
        variant="outlined"
        fullWidth
        className={props.error ? "animate-shake" : undefined}
        autoComplete="off"
        sx={theme => ({
          // cursor: typingMode === "none" ? "pointer" : undefined,
          cursor: "pointer",
          ".MuiOutlinedInput-root": {
            // pointerEvents: typingMode === "none" ? "none" : undefined,
            pointerEvents: "none",
            boxShadow: `0 1px 40px ${theme.palette.background.default}`,
            borderRadius: "35px",
            background: theme.palette.background.default,
            "fieldset": { border: "none" },
            "label": { paddingLeft: 1 },
            "input": { paddingLeft: 3 }
          },
          ".MuiFormHelperText-root": {
            color: theme.palette.error.main
          }
        })}
        onClick={(e) => {
          // if (props.typingMode !== "none") return
          setAnchorEl(e.currentTarget)
          setMenuWidth(e.currentTarget.getBoundingClientRect().width)
        }}
        {...params}
      />
    )
  }

  if (!props.options || !props.options.length) return null

  return (
    <Box width={props.fullWidth ? 1 : 0.8} mt={sm ? 2 : 2}>
      {props.autoComplete ? (
        <Autocomplete
          options={props.options}
          // groupBy={(option) => option.firstLetter}
          clearIcon={false}
          popupIcon={false}
          onChange={(e, v) => props.onChange(v.value)}
          value={props.options.find(a => a.value === props.value) || undefined}
          getOptionLabel={(option) => option.text}
          renderInput={(params) => renderTextField(params)}
        />
      ) : (
        <>
          {renderTextField()}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            PaperProps={{sx: { width: menuWidth, borderRadius: "15px" }}}
          >
            {props.options.map(op => (
              <MenuItem key={op.value} value={op.value} onClick={() => onChange(op.value)}>
                {op.text}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </Box>
  )
}
