import React from "react"
import Box from "@mui/material/Box"

export const Disabled = ({when = false, children}: {when: boolean, children: ReactElement<any, any>}) => {
  return (
    <Box
      sx={{
        pointerEvents: when ? "none" : undefined,
        opacity: when ? 0.5 : undefined
      }}
    >
      {children}
    </Box>
  )
}
