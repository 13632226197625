import React, {useState} from "react"
import MainStore from "stores/main-store"
import AlertsStore from "stores/alerts-store"
import Box from "@mui/material/Box"
import { Dialog, DialogContent, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { AppTextField } from "components/common/app-controls/app-text-field"
import { AppButton } from "components/common/app-controls/app-button"
import { Disabled } from "components/common/disabled"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { AppSelectField } from "../common/app-controls/app-select-field"
import { observer } from "mobx-react"
import { Close } from "@mui/icons-material"
import { SettingsOutlined } from "@mui/icons-material"
import { DialogTopIconButton } from "../common/app-controls/dialog-top-icon-button"
import { AlertTypes } from "../../stores/alerts-store.types"

export const AddAlert = observer(({}) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))
  const [ mainStore ] = useState(() => MainStore)
  const [ alertsStore ] = useState(() => AlertsStore)
  const [ isSaving, setIsSaving ] = useState(false)

  // assets autocomplete
  const onSubmit = async (formData) => {
    setIsSaving(true)
    await alertsStore.createUserAlert(formData)
    setIsSaving(false)
    onClose()
  }

  const onClose = () => {
    alertsStore.setAddAlertPopup({open: false})
  }

  const onSetupAlertsClick = () => {
    alertsStore.setAddAlertPopup({open: false})
    alertsStore.setSetupAlertsPopup({open: true})
  }

  return (
    <Dialog
      open={alertsStore.addAlertPopupOpen}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "60px", maxWidth: 600 } }}
      fullWidth
    >
      <DialogContent>
        <Formik
          initialValues={{ field: "hype", operator: "=", asset: alertsStore.addAlertPopupDefaultSelectedAsset, value: "" }}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
          validationSchema={
            Yup.object().shape({
              asset: Yup.string()
                .required("Please select an asset from the list"),
              value: Yup.number()
                .required("Please insert a number")
                .typeError("Please insert a number")
            })}
        >
          {({ errors, setValues, values }) => (
            <Form>
              <Disabled when={isSaving}>
                <Box width={1} display="flex" justifyContent="center" alignItems="center" flexDirection="column">

                  <DialogTopIconButton position="right" tooltipTitle="Close" icon={<Close/>} onClick={onClose} />
                  <DialogTopIconButton position="left" tooltipTitle="Setup Alerts" icon={<SettingsOutlined/>} onClick={onSetupAlertsClick} />

                  <Typography variant="h6" width={1} align="center" fontWeight="700" my={2} fontSize={sm ? 15 : 20}>
                    Add Alert
                  </Typography>

                  <Typography variant="body1" align="center" fontSize={sm ? 12 : 15}>
                    Get notified when special events happens in your assets
                  </Typography>

                  <Box mt={sm ? 1 : 2} />

                  <AppSelectField
                    value={(values.asset || "").toString()}
                    onChange={(asset) => setValues({ ...values, asset })}
                    label="Asset"
                    options={mainStore.allAssets.map(a => ({value: a.id.toString(), text: `${a.symbol} - ${a.name}`}))}
                    fullWidth
                    autoComplete
                    error={errors.asset}
                  />

                  <Box mt={sm ? 1 : 2} />

                  <Box display="flex" gap={1}>

                    <AppSelectField
                      value={values.field}
                      onChange={(field) => setValues({ ...values, field })}
                      label="Parameter"
                      options={[
                        { value: "hype", text: "Hype" },
                        { value: "price", text: "Price" }
                      ]}
                    />

                    <AppSelectField
                      value={values.operator}
                      onChange={(operator) => setValues({ ...values, operator })}
                      label="Operator"
                      options={AlertTypes.getAll()}
                    />

                    <AppTextField
                      value={values.value}
                      onChange={(value) => setValues({ ...values, value })}
                      label="Value"
                      placeholder="Enter your pref"
                      error={errors.value}
                    />

                  </Box>

                  <Box mb={1} width={0.8} display="flex" justifyContent="center">
                    <AppButton showLoader={isSaving}>
                      Add Alert
                    </AppButton>
                  </Box>

                </Box>
              </Disabled>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
})
