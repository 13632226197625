import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import { Link, Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import MainStore from "stores/main-store"
import moment from "moment"
import { observer } from "mobx-react"
import InfoIcon from "@mui/icons-material/Info"
import CloseIcon from "@mui/icons-material/Close"
import { IScrollData, useScrollPagination } from "components/common/hooks/useScrollPagination"
import { SentimentAnalysisBadge } from "components/common/sentiment-analysis-badge"
import { useTheme } from "@mui/material/styles"
import GaugeComponent from "react-gauge-component"
import { TextBadge } from "../common/text-badge"

const ArticleActiveIcon = require("assets/icons/article-active.svg")
const HalfCircleLight = require("assets/images/half-circle-light.svg")
const HalfCircleDark = require("assets/images/half-circle-dark.svg")

export const ArticleListDrawer = observer(({dateValue, articleId = "0"}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [ scrollData, setScrollData ] = useState<IScrollData>(undefined)
  const { assetId } = useParams()
  const [ mainStore ] = useState(() => MainStore)
  const [ isArticlesVisible, setIsArticlesVisible ] = useState(false)
  const [ articles, setArticles ] = useState([])
  const article = articles.find(a => a.id === parseInt(articleId))
  const [ scrollingIntoView, setScrollingIntoView ] = useState(false)
  const [ isScrollable, setIsScrollable ] = useState(false)
  const [ renderedItems ] = useScrollPagination(articles, scrollData, articles.indexOf(article))
  const [ showGauge, setShowGauge ] = useState(false)
  const [ gaugeData, setGaugeData ] = useState({ score: 0, text: "", color: "" })
  const gaugeStops =  [
    { limit: 10, color: "#df466f", showTick: false },
    { limit: 30, color: "#e1815d", showTick: false },
    { limit: 50, color: "#ebd054", showTick: false },
    { limit: 70, color: "#92c66b", showTick: false },
    { limit: 90, color: "#26ac8b", showTick: false }
  ]

  useEffect(() => {
    if (mainStore.currentAssetData) {
      const asset = mainStore.leaderboardAssets.find(b => b.id.toString() === mainStore.currentAssetData?.id)
      let gData;
      if (asset) {
        console.log(asset.sentimentString, asset.hype)
        if (asset.sentimentString === "very negative") {
          gData = { score: 10, text: "Extreme Sell" }
        }
        if (asset.sentimentString === "negative") {
          gData = { score: 30, text: "Sell" }
        }
        if (asset.sentimentString === "neutral") {
          gData = { score: 50, text: "Neutral" }
        }
        if (asset.sentimentString === "positive" && asset.hype >= 75) {
          gData = { score: 70, text: "Buy" }
        }
        if (asset.sentimentString === "very positive" && asset.hype >= 0) {
          gData = { score: 70, text: "Buy" }
        }
        if (asset.sentimentString === "very positive" && asset.hype >= 100) {
          gData = { score: 90, text: "Strong Buy" }
        }
        if (asset.sentimentString === "positive" && asset.hype >= 250) {
          gData = { score: 90, text: "Strong Buy" }
        }
        if (!gData) return
        gData.color = gaugeStops.find(a => a.limit === gData.score).color
        setGaugeData(gData)
        setShowGauge(true)
      } else {
        setShowGauge(false)
      }
    }
  }, [ mainStore.currentAssetData ])

  useEffect(() => {
    if (mainStore.currentHypeChartSelection && mainStore.currentAssetArticles) {
      setArticles(mainStore.currentAssetArticles[mainStore.currentArticleListDateValue])
    }
  }, [])

  useEffect(() => {
    mainStore.getAssetArticles(assetId)
    setArticles([])
  }, [ assetId ])

  useEffect(() => {
    if (!dateValue) return

    if (dateValue !== "all") {
      if (mainStore.currentAssetArticles) {
        if (mainStore.currentHypeChartSelection) {
          setArticles(mainStore.currentAssetArticles[mainStore.currentArticleListDateValue] || [])
        } else if (mainStore.currentAssetArticles[dateValue]) {
          setArticles(mainStore.currentAssetArticles[dateValue])
          mainStore.setCurrentArticleListDateValue(dateValue)
        }
      }
    } else {
      if (mainStore.currentAssetArticlesFlatten) {
        setArticles(mainStore.currentAssetArticlesFlatten)
      }
    }
  }, [ mainStore.currentAssetArticles, mainStore.currentAssetArticlesFlatten, dateValue ])

  useEffect(() => {
    setScrollingIntoView(true)
  }, [ mainStore.currentArticle, isArticlesVisible ])

  useEffect(() => {
    if (scrollingIntoView === true) {
      setTimeout(() => {
        const el = document.querySelector(".scroll-into-view")
        if (!el) return
        el.scrollIntoView({ behavior: "smooth", block: "center" })
        setTimeout(() => setScrollingIntoView(false), 500)
      }, 100)
    }
  } ,[ scrollingIntoView ])

  useEffect(() => setIsArticlesVisible(!mainStore.isMobile), [ mainStore.isMobile ])

  useEffect(() => {
    const el = document.getElementById("articles-content")
    setTimeout(() => setIsScrollable(el.scrollHeight > el.clientHeight), 0)
  }, [ articles ])

  const onListScroll = (e) => {
    if (scrollingIntoView) return

    setScrollData({
      scrollTop: e.target.scrollTop,
      scrollHeight: e.target.scrollHeight
    })
  }

  const onArticleClick = (article) => {
    if (mainStore.isMobile) {
      setIsArticlesVisible(false)
    }
    navigate(`/article/${assetId}/${article.id}`)
  }

  return (
    <>
      <Box
        position="absolute"
        right={0}
        top={5}
        height={58}
        width={60}
        bgcolor="background.default"
        borderRadius="30px 0 0 30px"
        boxShadow={`0 0 40px ${theme.palette.background.default}`}
        display={isArticlesVisible ? "none" : "flex"}
        alignItems="center"
        justifyContent="center"
        onClick={() => setIsArticlesVisible(true)}
      >
        <img src={ArticleActiveIcon} width={24} height={24} />
      </Box>
      <Box
        display={isArticlesVisible ? undefined : "none"}
        width={mainStore.isMobile ? undefined : 320}
        bottom={mainStore.isMobile ? 0 : 20}
        top={mainStore.isMobile ? 0 : 20}
        left={mainStore.isMobile ? 0 : undefined}
        right={0}
        bgcolor="background.default"
        position="absolute"
        borderRadius="10px 0 0 10px"
        boxShadow={`0 0 40px ${theme.palette.background.default}`}
        sx={{ transition: "right 0.5s ease-out" }}
        zIndex={1000}
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between" p={5} pb={3}>
          <Typography variant="button" color="text.primary" fontWeight={600} display="block">
            Articles
          </Typography>
          <CloseIcon
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              display: mainStore.isMobile ? undefined : "none"
            })}
            onClick={() => setIsArticlesVisible(false)}
          />
        </Box>
        <Box id="articles-content" sx={{overflowY: "auto"}} position="absolute" top={90} bottom={showGauge ? 230 : 20} width={1} onScroll={onListScroll}>
          {!renderedItems.length && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              pt={2}
              width={1}
              height={mainStore.isMobile ? 1 : 0.5}
            >
              <InfoIcon
                fontSize="large"
                sx={(theme) => ({ color: theme.palette.text.secondary })}
              />
              <Typography variant="body2" color="text.secondary" fontSize={16} fontWeight="bold" py={1}>
                No data
              </Typography>
              <Typography variant="body2" color="text.secondary" fontSize={13}>
                Hover over the chart to see articles
              </Typography>
            </Box>
          )}
          {renderedItems.map(article => (
            <Box
              key={article.id}
              px={5} py={1}
              sx={(theme) => ({
                cursor: "pointer",
                background: articleId === article.id.toString() ? theme.palette.background.paper : undefined ,
                "&:hover": { background: theme.palette.background.paper }
              })}
              className={articleId === article.id.toString() ? "scroll-into-view" : undefined}
              onClick={() => onArticleClick(article)}
            >
              <Box position="relative" left={-7}>
                <SentimentAnalysisBadge sentimentString={article.sentimentString} />
              </Box>
              <Typography variant="body2" color="text.secondary" fontSize={11} mt={0.5}>
                {article.headline}
              </Typography>
              <Typography variant="body2" color="text.secondary" fontSize={10} sx={{opacity: 0.7}}>
                {moment(article.datetime).format("YYYY/MM/DD")} | {article.textSource}
              </Typography>
            </Box>
          ))}
        </Box>

        {showGauge && (
          <Box position="absolute" right={0} left={0} bottom={30} zIndex={100} display="flex" alignItems="center" justifyContent="center">
            <Box
              position="relative"
              width={mainStore.isMobile ? 300 : 350}
              px={mainStore.isMobile ? 5 : 0}
              display={isArticlesVisible ? "block" : "none"}
              sx={{
                "& .pointer circle": { transform: "translate(0, -5px)" },
                "& .semicircle-gauge": { position: "relative", top: mainStore.isMobile ? 10 : 0 }
              }}
            >
              {isScrollable && (
                <Link
                  component="button" variant="body2" sx={{ position: "absolute" }}
                  left={0} top={-30} width={1} textAlign="center" color="primary.main"
                  onClick={() => setShowGauge(false)}
                >
                  See all...
                </Link>
              )}

              <GaugeComponent
                arc={{ gradient: true, width: 0.09, subArcs: gaugeStops }}
                value={gaugeData.score}
                type="semicircle"
                pointer={{ type: "blob", width: 14 }}
                labels={{ valueLabel: { hide: true }, tickLabels: { hideMinMax: true } }}
              />

              <Box
                position="absolute" top={0} left={30} right={30} bottom={0} borderRadius={5}
                boxShadow={`0 0 15px ${mainStore.darkMode ? theme.palette.background.paper : "lightgray"}`}
              />

              <Box
                position="absolute" top={mainStore.isMobile ? 30 : 35} left={0} right={0} bottom={0}
                display="flex" alignItems="center" justifyContent="center"
              >
                {mainStore.darkMode ? (
                  <img
                    src={HalfCircleDark} alt="HalfCircle"
                    style={{ pointerEvents: "none", width: "75%" }}
                  />
                ) : (
                  <img
                    src={HalfCircleLight} alt="HalfCircle"
                    style={{ pointerEvents: "none", width: "75%" }}
                  />
                )}
              </Box>

              <Box
                position="absolute" width={1} top={mainStore.isMobile ? 75 : 65} left={0} display="flex"
                flexDirection="column" alignItems="center"
              >
                <Typography variant="h4" color="text.secondary">
                  {gaugeData.score}
                </Typography>
                <TextBadge text={gaugeData.text} color={gaugeData.color} />
              </Box>

            </Box>
          </Box>
        )}

      </Box>


    </>
  )
})
