export default class BrowserNotificationsService {
  public static init() {}

  public static notify(text: string, options) {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      console.warn("This browser does not support desktop notification")
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted
      // if so, create a notification
      const notification= new Notification(text, options)
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          const notification = new Notification(text, options)
        }
      })
    }

    // be respectful, there is no need to bother the user anymore.
  }
}
