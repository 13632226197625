import { makeAutoObservable } from "mobx"
import { globalInstance } from "utils/singleton"
import FirebaseService from "services/firebase-service"
import { IUser } from "./user-store.types"

class UserStore {
  constructor() {
    makeAutoObservable(this)
    FirebaseService.init().then(() => {
      this.initiated = true
      this.readUserData()
    })
  }

  public initiated: boolean = false
  public currentUserData: IUser = {}

  public async sendConfirmationEmail(email: string) {
    const userResponse = await FirebaseService.createUserWithEmailAndPassword(email, "password")
    await userResponse.user.delete()
    await FirebaseService.sendSignInLinkToEmail(email)
    localStorage.setItem("email_for_signin", email)
  }

  public async sendPasswordResetEmail(email: string) {
    await FirebaseService.sendPasswordResetEmail(email)
    localStorage.setItem("email_for_reset", email)
  }

  public async validateEmailLink() {
    const email = localStorage.getItem("email_for_signin")
    const userResponse = await FirebaseService.validateEmailConfirmation(email)
    await userResponse.user.delete()
  }

  public async validateResetPasswordLink(code: string) {
    const email = localStorage.getItem("email_for_reset")
    const responseEmail = await FirebaseService.verifyPasswordResetCode(code)
    if (email !== responseEmail) throw "link validation error: email don't match"
    localStorage.removeItem("email_for_reset")
  }

  public async createUser(password: string, firstName: string, lastName: string) {
    const email = localStorage.getItem("email_for_signin")
    const userResponse = await FirebaseService.createUserWithEmailAndPassword(email, password)
    await FirebaseService.writeUserData(userResponse.user.uid, email, firstName, lastName)
    const userData = { firebaseId: userResponse.user.uid, email, firstName, lastName, isProUser: false }
    this.setCurrentUserData(userData)
    // localStorage.setItem("hype_user_data", JSON.stringify(this.currentUserData))
  }

  public async resetPassword(code: string, newPassword: string) {
    await FirebaseService.confirmPasswordReset(code, newPassword)
  }

  public async loginUser(email: string, password: string) {
    const userResponse = await FirebaseService.signInWithEmailAndPassword(email, password)
    const userData = await FirebaseService.readUserData(userResponse.user.uid)
    this.setCurrentUserData(userData)
    // localStorage.setItem("hype_user_data", JSON.stringify(this.currentUserData))
  }

  public async logoutUser() {
    this.currentUserData = {}
    // localStorage.removeItem("hype_user_data")
    if (!this.currentUser) return
    await FirebaseService.signOut()
  }

  public get currentUser() {
    return FirebaseService.currentUser
  }

  public async readUserData() {
    if (!this.currentUser?.uid) return
    const userData = await FirebaseService.readUserData(this.currentUser.uid)
    this.setCurrentUserData(userData)
  }

  public setCurrentUserData(userData: IUser) {
    this.currentUserData = userData
    // localStorage.setItem("hype_user_data", JSON.stringify(this.currentUserData))
  }

  public async toggleUserFavoriteAsset(favoriteAsset: number) {
    const favAssets = this.currentUserData.favoriteAssets || []
    const on = !favAssets.includes(favoriteAsset)

    if (on) {
      favAssets.push(favoriteAsset)
    } else {
      const idx = favAssets.indexOf(favoriteAsset)
      favAssets.splice(idx, 1)
    }

    this.currentUserData.favoriteAssets = favAssets
    await FirebaseService.updateUserData(this.currentUserData)
    this.setCurrentUserData({ ...this.currentUserData })
  }
}

export default globalInstance("userStore", UserStore)
