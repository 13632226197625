import React from "react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useTheme } from "@mui/material/styles"
import { Box, Switch, Typography } from "@mui/material"
import { AppLeftMenuButton } from "./app-left-menu-button"
import { observer } from "mobx-react"
import mainStore from "stores/main-store"
import { sortByField } from "utils/helpers"
import MainStore from "../../stores/main-store"

const IconLogoBlack = require("assets/logo/hypeindex-icon-logo-black.svg")
const IconLogoWhite = require("assets/logo/hypeindex-icon-logo-white.svg")
const TextLogoBlack = require("assets/logo/hypdx-text-black.svg")
const TextLogoWhite = require("assets/logo/hypdx-text-white.svg")
const LeaderBoardIcon = require("assets/icons/leader-board.svg")
const LeaderBoardActiveIcon = require("assets/icons/leader-board-active.svg")
const HeatMapIcon = require("assets/icons/heat-map.svg")
const HeatMapActiveIcon = require("assets/icons/heat-map-active.svg")
const GraphIcon = require("assets/icons/graph.svg")
const GraphActiveIcon = require("assets/icons/graph-active.svg")
const ArticleIcon = require("assets/icons/article.svg")
const ArticleActiveIcon = require("assets/icons/article-active.svg")
const ArticleFeedIcon = require("assets/icons/article-feed.svg")
const ArticleFeedActiveIcon = require("assets/icons/article-feed-active.svg")
const ReportIcon = require("assets/icons/report.svg")
const ReportActiveIcon = require("assets/icons/report-active.svg")

export const AppLeftMenu = observer((): JSX.Element => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [ mobileProps, setMobileProps ] = useState({})
  const [ mainStore ] = useState(() => MainStore)
  const sortedLeaderboardAssets = (mainStore.leaderboardAssets?.length && sortByField(mainStore.leaderboardAssets, "hype").reverse())
  const currentAssetId = mainStore.currentAssetData?.id || sortedLeaderboardAssets?.length && sortedLeaderboardAssets[0].id
  const currentArticleId = mainStore.currentArticle?.id || mainStore.currentAssetArticlesFlatten?.length && mainStore.currentAssetArticlesFlatten[0].id
  const width = mainStore.isMobile ? 70 : 100

  useEffect(() => {
    if (mainStore.isMobile) {
      setMobileProps({
        width: "100%",
        height: "auto",
        zIndex: 100,
        flexDirection: "row",
        justifyContent: "space-evenly",
        position: "fixed",
        bottom: 0,
        sx: { background: theme.palette.background.default }
      })
    } else {
      setMobileProps({})
    }
  }, [ mainStore.isMobile ])

  return (
    <Box
      position="relative"
      display="flex"
      height="100%"
      width={width}
      flexDirection="column"
      justifyContent="start"
      alignItems="center"
      sx={{
        // backgroundImage: "linear-gradient(to bottom, rgba(255, 246, 240, 0.38) 0%, rgba(251, 232, 233, 0.31) 57%, rgba(252, 252, 255, 0.57))"
        background: theme.palette.background.default
      }}
      {...mobileProps}
    >
      {!mainStore.isMobile && (
        <Box width={50} height={80} display="flex" flexDirection="column" justifyContent="space-between" padding={3}>
          {mainStore.darkMode ? (
            <>
              <img src={IconLogoWhite} alt="Home" title="Home" onClick={() => navigate("/")} />
              <img src={TextLogoWhite} alt="logo" width={50} />
            </>
          ) : (
            <>
              <img src={IconLogoBlack} alt="Home" title="Home" onClick={() => navigate("/")} />
              <img src={TextLogoBlack} alt="logo" width={50} />
            </>
          )}
        </Box>
      )}
      <AppLeftMenuButton
        icon={LeaderBoardIcon}
        activeIcon={LeaderBoardActiveIcon}
        isActive={location.pathname.startsWith("/leaderboard") || location.pathname === "/"}
        onClick={() => navigate("/")}
        alt="HypeIndex leaderboard, this page will be updated constantly with the most hyped assets of the same day.
        The daily change is calculated by comparing to the average of the last 12 weeks."
        tooltip="Leaderboard"
      />
      <AppLeftMenuButton
        icon={HeatMapIcon}
        activeIcon={HeatMapActiveIcon}
        isActive={location.pathname.startsWith("/heatmap")}
        onClick={() => navigate("/heatmap")}
        alt="HypeIndex leaderboard as a heatmap."
        tooltip="Heatmap"
      />
      <AppLeftMenuButton
        icon={GraphIcon}
        activeIcon={GraphActiveIcon}
        isActive={location.pathname.startsWith("/hype")}
        onClick={() => navigate(`/hype/${currentAssetId}`)}
        alt="Asset page, here you can analyse the graph of each asset with the correlation of price and Hype."
        tooltip="Asset"
      />
      <AppLeftMenuButton
        icon={ArticleIcon}
        activeIcon={ArticleActiveIcon}
        isActive={location.pathname.startsWith("/article/")}
        onClick={() => navigate(`/article/${currentAssetId}/${currentArticleId}`)}
        alt="Article page, Watch all the articles that are related to your chosen asset with a sentiment score for each one of them."
        tooltip="Article"
      />
      <AppLeftMenuButton
        icon={ArticleFeedIcon}
        activeIcon={ArticleFeedActiveIcon}
        isActive={location.pathname.startsWith("/article-feed")}
        onClick={() => navigate(`/article-feed`)}
        alt="Article news feed, Be on top of the news, never miss an important news."
        tooltip="Article feed"
      />
      <Box>
        <AppLeftMenuButton
          icon={ReportIcon}
          activeIcon={ReportActiveIcon}
          width={32}
          isActive={location.pathname.startsWith("/ai-report")}
          onClick={() => navigate(`/ai-report`)}
          alt=""
          tooltip="AI Report"
        />
      </Box>
      {!mainStore.isMobile && (
        <Box position="absolute" bottom={5} display="flex" flexDirection="column" alignItems="center">
          <Switch checked={mainStore.darkMode} onChange={(e) => mainStore.setDarkMode(e.target.checked)} />
          <Typography variant="body2" textTransform="uppercase" color={theme.palette.text.disabled} fontSize={12}>
            Dark Mode
          </Typography>
        </Box>
      )}
    </Box>
  )
})
