import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import { useTheme } from "@mui/material/styles"
import { TextField, useMediaQuery } from "@mui/material"
import { OutlinedTextFieldProps } from "@mui/material/TextField/TextField"

interface IProps extends Omit<OutlinedTextFieldProps, "variant" | "onChange" | "error"> {
  label?: string
  value?: string
  placeholder?: string
  inlineComponent?: JSX.Element
  error?: string
  onChange?: (value: string) => void
  password?: boolean
}

export const AppTextField = (props: IProps) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const onChange = (e) => {
    const inputValue = e.target.value
    if (props.onChange) props.onChange(inputValue)
  }

  return (
    <Box width={0.8} mt={sm ? 2 : 2}>
      <TextField
        label={props.label}
        placeholder={props.placeholder}
        helperText={props.error}
        value={props.value}
        onChange={onChange}
        onKeyUp={props.onKeyUp}
        variant="outlined"
        fullWidth
        type={props.password ? "password" : undefined}
        className={props.error ? "animate-shake" : undefined}
        sx={theme => ({
          ".MuiOutlinedInput-root": {
            boxShadow: "0 1px 40px rgba(122, 153, 206, 0.21)",
            borderRadius: "35px",
            "fieldset": { border: "none" },
            "label": { paddingLeft: 1 },
            "input": { paddingLeft: 3 }
          },
          ".MuiFormHelperText-root": {
            color: theme.palette.error.main
          }
        })}
      />
      {props.inlineComponent}
    </Box>
  )
}
