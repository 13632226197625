import { IAsset } from "./main-store.types"
import { IUser } from "./user-store.types"
import { bool } from "yup"

export const AlertTypes = {
  "=": "Equals",
  ">": "Bigger than",
  "<": "Smaller than",
  getAll: () => Object.keys(AlertTypes)
    .filter(at => at !== "getAll")
    .map(at => ({value: at, text: `${AlertTypes[at]} ( ${at} )`}))
}

export interface IAlert {
  id: number
  assetId: number
  userId: number
  asset: IAsset
  user: IUser
  fieldType: string
  alertType: string
  testValue: string
  createdAt: string
  updatedAt: string
  enabled: boolean
}

export interface INotification {
  id: number
  alertId: number
  alert: IAlert
  appUserId: number
  isRead: boolean
  notificationText: string
  actualValue: string
  dateTime: string
  createdAt: string
  updatedAt: string
}
