import React from "react"
import "./page-loader.css"
import Box from "@mui/material/Box"
import { Fade } from "@mui/material"

const FullLogoWhite = require("assets/logo/full-logo-white.svg")

export const PageLoader = () => {
  return (
    <Fade in timeout={1200}>
      <Box className="page-loader">
        <img src={FullLogoWhite} alt="logo" title="logo" width={300} />
        <Box className="spinner" mt={10} />
      </Box>
    </Fade>
  )
}
