import React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import { useMediaQuery } from "@mui/material"

export const LayoutColumn = ({ title, value, color }: { title: string, value?: string, color?: string }) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Box minWidth={sm ? 110 : 130} maxWidth={sm ? 110 : 130}>
      <Typography
        variant="body2"
        width={1}
        align="left"
        fontWeight="400"
        fontSize={sm ? 11 : 13}
        color={theme.palette.text.disabled}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        fontWeight="300"
        align="left"
        fontSize={sm ? 18 : 22}
        color={color || "text.secondary"}
      >
        {value || "No data"}
      </Typography>
    </Box>
  )
}
