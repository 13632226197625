import React from "react"
import Box from "@mui/material/Box"
import { FilterAlt, FilterAltOutlined } from "@mui/icons-material"
import Typography from "@mui/material/Typography"

export const ColumnFilterLabel = ({ column, label, labelInfo, setFilterPopoverProps, tableFilters, setTableFilters, filterType, fixedHeight }) => {

  return (
    <Box display="flex" position="relative" left={-5}>
      <Box position="relative" top={4} left={-5} onClick={(e) => {
        e.stopPropagation()
        setFilterPopoverProps({
          anchorEl: e.currentTarget.parentElement.parentElement.parentElement.parentElement,
          column, filterType, fixedHeight
        })
        if (!tableFilters.find(tf => tf.column === column)) {
          setTableFilters([ ...tableFilters, { column, filterType, filterData: [] } ])
        }
      }}>
        {tableFilters.find(tf => tf.column === column)?.filterOn ? (<FilterAlt fontSize="small" />) : (<FilterAltOutlined fontSize="small" />)}
      </Box>
      {label}
      <Typography variant="overline" fontSize="x-small" color="text.secondary" sx={{ position: "absolute", bottom: -14, left: -2 }}>
        {labelInfo}
      </Typography>
    </Box>
  )
}
