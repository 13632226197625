import React, { useEffect } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { observer } from "mobx-react"
import { IAsset, ILeaderboardAsset } from "stores/main-store.types"
import { EnhancedTable, IHeadCell } from "components/common/enhanced-table"
import userStore from "stores/user-store"
import { SentimentAnalysisBadge } from "components/common/sentiment-analysis-badge"
import { NorthOutlined, Star, StarOutline } from "@mui/icons-material"
import { FilterPopover } from "./filter-popover"
import { ColumnFilterLabel } from "./column-filter-label"
import mainStore from "stores/main-store"
import { Tooltip } from "@mui/material"
import { AddAssetToFavoritesButton } from "../../components/common/add-asset-to-favorites-button"

const headCells = (setFilterPopoverProps, tableFilters, setTableFilters): IHeadCell[] => [
  {
    id: "position",
    numeric: false,
    disablePadding: true,
    label: "#",
    displayOnMobile: false,
    align: "left",
    width: "5%",
    renderValue: (row: ILeaderboardAsset) => {
      const positionChange = row.positionChange || ""
      return (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" gap={1}>
          {userStore.currentUserData?.firebaseId && (
            <AddAssetToFavoritesButton assetId={row.id} />
          )}
          <Typography color="text.secondary" display="flex">{row.position}</Typography>
          {positionChange === "Same" ? (
            <Box bgcolor="#eff0f2" borderRadius={24} display="flex" flexDirection="row" alignItems="center" p={0.5}>
              <NorthOutlined style={{ width: 16, height: 16, transform: "rotate(90deg)", color: "gray" }} />
            </Box>
          ) : positionChange.includes("-") ? (
            <Box bgcolor="#ffe9eb" color="red" borderRadius={24} display="flex" flexDirection="row" alignItems="center" p={0.5}>
              <Typography fontSize={14}>{positionChange}</Typography>
              <NorthOutlined style={{ width: 16, height: 16, transform: "rotate(180deg)" }} />
            </Box>
          ) : (
            <Box bgcolor="#e0f8ef" color="green" borderRadius={24} display="flex" flexDirection="row" alignItems="center" p={0.5}>
              <Typography fontSize={14}>{positionChange}</Typography>
              <NorthOutlined style={{ width: 16, height: 16 }} />
            </Box>
          )}
        </Box>
      )
    }
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Asset",
    displayOnMobile: false,
    align: "left",
    width: "30%",
    blurCell: (row: ILeaderboardAsset) => row.isPro && !userStore.currentUserData?.isProUser
  },
  {
    id: "symbol",
    numeric: true,
    disablePadding: false,
    label: "Symbol",
    width: "15%",
    blurCell: (row: ILeaderboardAsset) => row.isPro && !userStore.currentUserData?.isProUser
  },
  {
    id: "industry",
    numeric: false,
    disablePadding: false,
    display: mainStore.assetType !== "crypto",
    label: (
      <ColumnFilterLabel
        column="industry" label="Industry" labelInfo="" filterType="list" fixedHeight
        setFilterPopoverProps={setFilterPopoverProps} tableFilters={tableFilters} setTableFilters={setTableFilters}
      />
    ),
    tooltip: true,
    width: "15%",
    blurCell: (row: ILeaderboardAsset) => row.isPro && !userStore.currentUserData?.isProUser
  },
  {
    id: "hype",
    numeric: true,
    disablePadding: false,
    label: (
      <ColumnFilterLabel
        column="hype" label="Hype %" labelInfo="(daily change)" filterType="range"
        setFilterPopoverProps={setFilterPopoverProps} tableFilters={tableFilters} setTableFilters={setTableFilters}
      />
    ),
    // info: "daily change",
    width: "15%",
    align: "center",
    renderValue: (row: ILeaderboardAsset) => {
      let color = "default"

      if (row.sentiment !== 0) {
        color = row.sentiment > 0 ? "success.main" : "error.main"
      }

      return row.hype >= 0 ? (
        <Typography fontSize="inherit" color={color} fontWeight="300">+ {row.hype}%</Typography>
      ) : (
        <Typography fontSize="inherit" color={color} fontWeight="300">- {0 - (row.hype as number)}%</Typography>
      )
    }
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    // label: "Price %",
    // info: "daily change",
    label: (
      <ColumnFilterLabel
        column="price" label="Price %" labelInfo="(daily change)" filterType="range"
        setFilterPopoverProps={setFilterPopoverProps} tableFilters={tableFilters} setTableFilters={setTableFilters}
      />
    ),
    width: "15%",
    align: "center",
    renderValue: (row: ILeaderboardAsset) => (
      (isNaN(row.price) || mainStore.assetType === "crypto") ? (
        <Typography fontSize="inherit" color="text.secondary" fontWeight="300">No data</Typography>
      ) : (
        row.price >= 0 ? (
          <Typography fontSize="inherit" color="success.main" fontWeight="300">+ {row.price}%</Typography>
        ) : (
          <Typography fontSize="inherit" color="error.main" fontWeight="300">- {0 - (row.price as number)}%</Typography>
        )
      )
    )
  },
  {
    id: "exchange",
    numeric: true,
    disablePadding: false,
    label: (
      <ColumnFilterLabel
        column="exchange" label="Exchange" labelInfo="" filterType="list"
        setFilterPopoverProps={setFilterPopoverProps} tableFilters={tableFilters} setTableFilters={setTableFilters}
      />
    ),
    tooltip: true,
    displayOnMobile: false,
    width: "15%",
  },
  {
    id: "sentiment",
    numeric: true,
    disablePadding: false,
    label: (
      <ColumnFilterLabel
        column="sentimentString" label="sentiment" labelInfo="" filterType="list"
        setFilterPopoverProps={setFilterPopoverProps} tableFilters={tableFilters} setTableFilters={setTableFilters}
      />
    ),
    displayOnMobile: true,
    overflow: "visible",
    width: "5%",
    align: "center",
    renderValue: (row: ILeaderboardAsset) => <SentimentAnalysisBadge sentimentString={row.sentimentString} />
  },
]

export const LeaderboardTable = observer(({ leaderboardAssets }: { leaderboardAssets: ILeaderboardAsset[] }) => {
  const navigate = useNavigate()
  const [ filteredAssets, setFilteredAssets ] = useState([])
  const [ filterPopoverProps, setFilterPopoverProps ] = useState(undefined)
  const [ tableFilters, setTableFilters ] = useState([])

  useEffect(() => {
    if (leaderboardAssets.length) setFilteredAssets(leaderboardAssets)
  }, [ leaderboardAssets ])

  useEffect(() => {
    const filtered = leaderboardAssets.filter(a =>
      tableFilters.every(tf => {
        // if (!tf.filterData?.length) return true
        if (tf.filterType === "list") {
          return tf.filterData.includes(a[tf.column])
        } else if (tf.filterType === "range") {
          const val = isNaN(parseFloat(a[tf.column])) ? 0 : parseFloat(a[tf.column])
          return val >= tf.filterData[0] && val <= tf.filterData[1]
        }
      }))

      setFilteredAssets(filtered)
  }, [ tableFilters ])

  const assetTypeFilter = (asset: IAsset) => {
    // if (mainStore.assetType === "all") return true
    if (mainStore.assetType === "crypto") return asset.exchange === "CRYPTO"
    if (mainStore.assetType === "favorites") return userStore.currentUserData?.favoriteAssets.includes(asset.id)
    return asset.exchange !== "CRYPTO"
  }

  const unique = (value, index, array) => array.indexOf(value) === index
  const currentFilterOptions = leaderboardAssets
    .filter(assetTypeFilter)
    .map(a => (a[filterPopoverProps?.column] || "").toString().includes("no data") ? 0 : a[filterPopoverProps?.column])
    .filter(unique)
  const currentFilterChosenOptions = tableFilters
    .find(tf => tf.column === filterPopoverProps?.column)?.filterData
  const optionsForRanges = currentFilterOptions.filter(op => !isNaN(parseFloat(op))).map(op => parseFloat(op))
  const ranges = [ Math.min.apply(null, optionsForRanges), Math.max.apply(null, optionsForRanges) ]

  return (
    <Box width={1} overflow="visible" mb={4}>
      <EnhancedTable
        tableCells={headCells(setFilterPopoverProps, tableFilters, setTableFilters)}
        tableData={filteredAssets}
        filter={assetTypeFilter}
        orderByDefault="position|asc"
        onRowClick={(row) => navigate(`/hype/${row.id}`)}
      />
      {filterPopoverProps && (
        <FilterPopover
          filterType={filterPopoverProps?.filterType}
          anchorEl={filterPopoverProps?.anchorEl}
          fixedHeight={filterPopoverProps?.fixedHeight}
          listOptions={filterPopoverProps?.filterType === "list" ? currentFilterOptions : ranges}
          chosenOptions={currentFilterChosenOptions}
          onClose={() => setFilterPopoverProps(undefined)}
          onFilter={(filterData) => {
            const tableFilter = tableFilters.find(tf => tf.column === filterPopoverProps.column)
            tableFilter.filterData = filterData
            if (filterPopoverProps?.filterType === "list") {
              tableFilter.filterOn = filterData.length !== currentFilterOptions.length
            } else if (filterPopoverProps?.filterType === "range") {
              tableFilter.filterOn = filterData[0] !== ranges[0] || filterData[1] !== ranges[1]
            }
            setTableFilters([ ...tableFilters ])
          }}
        />
      )}
    </Box>
  )
})
