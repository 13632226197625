import React from "react"
import { useEffect, useLayoutEffect, useState } from "react"
import Box from "@mui/material/Box"
import { observer } from "mobx-react"
import { useNavigate, useParams } from "react-router-dom"
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import MainStore from "stores/main-store"
import { ArticleListDrawer } from "components/article-list-drawer/article-list-drawer"
import { Badge, Dialog, DialogContent, Tooltip, Typography, useMediaQuery } from "@mui/material"
import { HypeChartLegend } from "./chart-legend"
import moment from "moment/moment"
import { PageLogo } from "../../components/common/page-logo"
import { useTheme } from "@mui/material/styles"
import { ProMembersOnlyDialog } from "components/common/pro-members-only-dialog"
import userStore from "../../stores/user-store"
import alertsStore from "../../stores/alerts-store"
import { SentimentAnalysisBadge } from "../../components/common/sentiment-analysis-badge"
import { AddAssetToFavoritesButton } from "../../components/common/add-asset-to-favorites-button"

const AddNotificationWhiteIcon = require("assets/icons/add-notification-white.svg")

let cursor
export const HypeChartPage = observer((): JSX.Element => {
  const theme = useTheme()
  const { assetId } = useParams()
  const [ mainStore ] = useState(() => MainStore)
  const [ currentDataPoint, setCurrentDataPoint ] = useState()
  const [ widthSetting, setWidthSetting ] = useState("100%")

  // useEffect(() => window.addEventListener("click", () => alert(window.innerHeight)), [  ])
  useEffect(() => {
    mainStore.getAssetData(assetId)
    if (cursor) {
      cursor.setAll({ positionX: undefined, positionY: undefined, alwaysShow: false })
    }
  }, [ assetId ])
  useEffect(() => {
    if (!mainStore.isMobile) {
      setWidthSetting("calc(100% - 50px)")
      setTimeout(() => setWidthSetting("100%"), 10)
    }
  }, [ mainStore.isMobile ])


  useLayoutEffect(() => {
    if (!mainStore.currentAssetData) return
    if (!mainStore.leaderboardAssets.length) return

    let root = am5.Root.new("chartdiv")

    root.setThemes([
      am5themes_Animated.new(root)
    ])

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        wheelY: "zoomX",
        layout: root.horizontalLayout,
      })
    );

// Create Y-axis
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 2,
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: true,
          minGridDistance: 200,
        })
      })
    );

    let yRenderer = yAxis.get("renderer");
    yRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });
    yRenderer.labels.template.setAll({
      fontSize: 12,
      fill: am5.color("#6f6c99")
    });

    // let yRenderer = yAxis.get("renderer");
    // yRenderer.grid.template.setAll({
    //   fillOpacity: 0,
    //   strokeWidth: 0,
    //   strokeOpacity: 0
    // });

    // yAxis.set("tooltip", am5.Tooltip.new(root, {
    //   themeTags: ["axis"]
    // }));


    var yAxis2 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        // syncWithAxis: yAxis,
        renderer: am5xy.AxisRendererY.new(root, {
          minGridDistance: 80,
        })
      })
    );

    let yRenderer2 = yAxis2.get("renderer");
    yRenderer2.grid.template.setAll({
      strokeOpacity: 0,
    });
    yRenderer2.labels.template.setAll({
      fontSize: 12,
      fill: am5.color("#6f6c99")
    });

    // yRenderer = yAxis2.get("renderer");
    // yRenderer.grid.template.setAll({
    //   fillOpacity: 0,
    //   strokeWidth: 0,
    //   strokeOpacity: 0
    // });

    // yAxis2.set("tooltip", am5.Tooltip.new(root, {
      // themeTags: ["axis"]
    // }));

// Create X-Axis
    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        groupData: true,
        baseInterval: { timeUnit: "day", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 100
        })
      })
    );

    let xRenderer = xAxis.get("renderer");
    xRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });
    xRenderer.labels.template.setAll({
      fontSize: 12,
      fill: am5.color("#6f6c99")
    });


    xAxis.get("dateFormats")["day"] = "MM/dd";
    xAxis.get("periodChangeDateFormats")["day"] = "MMMM";

    // xAxis.set("tooltip", am5.Tooltip.new(root, {
    //   themeTags: ["axis"]
    // }));

// Generate random data
    var date = new Date(2000);
    date.setHours(0, 0, 0, 0);
    var value = 100;

    function generateData() {
      value = Math.round((Math.random() * 10 - 5) + value);
      am5.time.add(date, "day", 1);
      return {
        date: date.getTime(),
        value: value
      };
    }

    function generateDatas(count) {
      var data = [];
      for (var i = 0; i < count; ++i) {
        data.push(generateData());
      }
      return data;
    }
    let data = mainStore.currentAssetData.priceData.map(a => ({
      date: new Date(a.datetime.replace(/-/g, "/")).getTime(),
      price: parseFloat(Intl.NumberFormat("en-US", { style: "decimal", maximumFractionDigits: 20 }).format(a.price).replace(",", "")),
      volume: a.volume
    }))

    let data2 = mainStore.currentAssetData.data.map(a => ({
      date: new Date(a.datetime.replace(/-/g, "/")).getTime(),
      hype: a.hype
    }))
    // var data = generateDatas(50000);
    // date = new Date(2000);
    // date.setHours(0, 0, 0, 0);
    // value = 100;
    // var data2 = generateDatas(50000);

// Create series
    function createSeries(name, field, valAxis, seriesData, color) {
      var series = chart.series.push(
        am5xy.SmoothedXYLineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: valAxis,
          valueYField: field,
          valueXField: "date",
          tooltip: am5.Tooltip.new(root, {
            labelText: `[fontSize: 12px]{name}: ${name === "USD" ? "$" : ""}{valueY}[/]`,
            pointerOrientation: "horizontal",
          }),
          fill: am5.color(color),
          tension: 0.8,
          stroke: am5.color(color),
          // tooltip: am5.Tooltip.new(root, {
          //   labelText: undefined,
          //   forceHidden: true,
          //   animationDuration: 0
          // })
        })
      );

      // series.strokes.template.set("strokeWidth", 4);
      series.strokes.template.setAll({
        strokeWidth: mainStore.isMobile ? 2 : 3,
        stroke: am5.color(0xAADD33),
        shadowColor: am5.color(0x000000),
        shadowBlur: 20,
        shadowOffsetX: 5,
        shadowOffsetY: 5,
        shadowOpacity: 0.3
      });
      // series.fills.template.setAll({
      //   fillOpacity: 0.5,
      //   visible: true
      // });

      // series.get("tooltip").label.set("text", "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}")
      series.data.setAll(seriesData);

      // Pre-zoom X axis
      // series.events.once("datavalidated", (ev, target) => {
      //   xAxis.zoomToDates(new Date(2021, 0, 1), new Date(2022, 0, 1))
      // })

      // series.bullets.push(function() {

      // create the circle first
      // var circle = am5.Circle.new(root, {
      //   radius: 6,
      //   stroke: series.get("fill"),
      //   strokeWidth: 2,
      //   interactive: true, //required to trigger the state on hover
      //   fill: am5.color(0xffffff),
      //   opacity: 0
      // });
      //
      // circle.states.create("default", {
      //   opacity: 0
      // });
      //
      // circle.states.create("hover", {
      //   opacity: 1
      // });
      //
      // return am5.Bullet.new(root, {
      //   sprite: circle
      // });
    // });

      return series

      // let lastX = 0
      // cursor.events.on("cursormoved", function(ev) {
      //   var x = ev.target.getPrivate("positionX");
      //   var y = ev.target.getPrivate("positionY");
      //   console.log(ev)
      //   // ev.target.triggerMove(ev.target.point, "soft");
      //
      //   // ev.target.set("positionY", xAxis.valueToPosition(1000))
      //
      //   // if (lastX !== x) {
      //   //   lastX = x
      //   //   cursor.setAll({
      //   //     positionX: x,
      //   //     // positionY: y + 10,
      //   //     alwaysShow: true
      //   //   });
      //   // }
      //
      //   var dateX = xAxis.positionToDate(xAxis.toAxisPosition(x));
      //   var valueY = xAxis.positionToValue(xAxis.toAxisPosition(x));
      //   console.log(dateX, valueY);
      //   console.log(data.find(a => a.date === valueY));
      // });

    }

    var series1 = createSeries("USD", "price", yAxis, data, "#8daef5");
    var series2 = createSeries("HYPDX", "hype", yAxis2, data2, "#ee8ad9");

    cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      xAxis: xAxis,
      // yAxis: yAxis2,
      behavior: "selectX",
      // snapToSeries: [series1, series2],
      snapToSeriesBy: "x"
      // positionY: 0,
      // positionX: 1,
    }))
    cursor.lineY.set("visible", false)

    cursor.events.on("cursormoved", cursorMoved);
    let currentDataItem = {}
    let currentCursorPosition = {}
    function cursorMoved(ev) {
      currentCursorPosition.positionX = ev.target.getPrivate("positionX")
      currentCursorPosition.positionY = ev.target.getPrivate("positionY")

      let dataItem = {}
      chart.series.each((series) => {
        if (series.get("tooltip").dataItem?.dataContext) {
          dataItem = { ...dataItem, ...series.get("tooltip").dataItem.dataContext }
        }
      })
      if (dataItem.volume === undefined) { delete dataItem.volume }
      currentDataItem = { ...currentDataItem, ...dataItem }
      setCurrentDataPoint(currentDataItem)
      // console.log(dataItem)
    }

    let cursorLocked = !!mainStore.currentHypeChartSelection
    chart.events.on("click", (ev) => {
      if (mainStore.isMobile) return

      cursorLocked = !cursorLocked

      const cursorSelection = {
        positionX: cursorLocked ? currentCursorPosition.positionX : undefined,
        positionY: cursorLocked ? currentCursorPosition.positionY : undefined,
        alwaysShow: cursorLocked
      }

      cursor.setAll(cursorSelection)

      if (cursorLocked) {
        mainStore.setCurrentHypeChartSelection({
          dataPoint: currentDataItem, // mind the currentDataItem and not currentDataPoint (because this is un-statefull area)
          cursor: cursorSelection
        })
      } else {
        mainStore.setCurrentHypeChartSelection(undefined)
      }
    })

    if (mainStore.currentHypeChartSelection) {
      setTimeout(() => cursor.setAll(mainStore.currentHypeChartSelection.cursor), 500)
      setCurrentDataPoint(mainStore.currentHypeChartSelection.dataPoint)
      currentDataItem = mainStore.currentHypeChartSelection.dataPoint
    }


    // function cursorMoved(ev) {
    //   var y = ev.target.getPrivate("positionY");
    //   ev.target.set("maxHeight", y)
    //
    //   for(var i = 0; i < previousBulletSprites.length; i++) {
    //     previousBulletSprites[i].unhover();
    //   }
    //   previousBulletSprites = [];
    //   chart.series.each(function(series) {
    //     var dataItem = series.get("tooltip").dataItem;
    //     if (dataItem) {
    //       var bulletSprite = dataItem.bullets[0].get("sprite");
    //       bulletSprite.hover();
    //       previousBulletSprites.push(bulletSprite);
    //     }
    //   });
    // }

    // cursor.setAll({
    //   positionX: 0.5,
    //   positionY: 0.5,
    //   alwaysShow: true
    // });

    let scrollbarX = am5xy.XYChartScrollbar.new(root, {
      orientation: "horizontal",
      height: window.innerHeight * (mainStore.isMobile ? 0.1 : 0.2)
    });

    chart.set("scrollbarX", scrollbarX);
    chart.bottomAxesContainer.children.push(scrollbarX);

    var sbxAxis = scrollbarX.chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        groupData: true,
        // groupIntervals: [{ timeUnit: "year", count: 1 }],
        baseInterval: { timeUnit: "day", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          opposite: false,
          strokeOpacity: 1
        })
      })
    );

    var sbyAxis = scrollbarX.chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    );

    var sbseries = scrollbarX.chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: sbxAxis,
        yAxis: sbyAxis,
        valueYField: "volume",
        valueXField: "date",
        fill: am5.color(0x4c8eff),
        background: am5.Rectangle.new(root, {
          stroke: am5.color(theme.palette.background.paper),
          fill: am5.color(theme.palette.background.paper),
          fillOpacity: 1
        })
      })
    );

    sbseries.columns.template.setAll({
      cornerRadiusTL: 3,
      cornerRadiusTR: 3,
      width: mainStore.isMobile ? 2 : 4
    });

    let sbyRenderer = sbxAxis.get("renderer");
    sbyRenderer.grid.template.setAll({
      strokeOpacity: 0,
    });
    sbyRenderer.labels.template.setAll({
      opacity: 0
      // fontSize: 12,
      // // fill: am5.color("#6f6c99"),
      // paddingTop: 100
    });


    sbseries.data.setAll(data);

// Add cursor

    // series.data.setAll(data)
    // series.set("selectedDataItem", series.dataItems[0])
    root.current = root

    return () => {
      root.dispose()
    }
  }, [ mainStore.currentAssetData, mainStore.leaderboardAssets, mainStore.isMobile, mainStore.darkMode ])

  const isProAsset = mainStore.currentAssetData?.asset?.isPro
  const isProUser = userStore.currentUserData?.isProUser

  const assetRowHasAlerts = alertsStore.allUserAlerts.some(a => a.assetId === mainStore.currentAssetData?.id)
  const asset = mainStore.leaderboardAssets.find(b => b.id.toString() === mainStore.currentAssetData?.id)

  let sentimentColor = "default"
  const hypeText = asset?.hype >= 0 ? `+ ${asset?.hype}%` : `- ${0 - asset?.hype}%`

  if (asset?.sentiment !== 0) {
    sentimentColor = asset?.sentiment > 0 ? "success.main" : "error.main"
  }

  return (
    <Box
      width={1} height={1} display="flex" position="relative" overflow="visible"
      style={{
        filter: (isProAsset && !isProUser) && "blur(5px)",
        userSelect: (isProAsset && !isProUser) && "none",
        pointerEvents: (isProAsset && !isProUser) && "none",
      }}
    >
      <Box display="flex" width={mainStore.isMobile ? 1 : "calc(100% - 320px)"} flexDirection="column" position="relative">
        <div id="chartdiv" style={{ width: widthSetting, height: "100%" }} />
        <HypeChartLegend dataPoint={currentDataPoint} />
        <Box position="absolute" height={20} top={0} left="50%" bgcolor={theme.palette.background.default} borderRadius={24} p={1} px={2} display="flex" alignItems="center" sx={{transform: "translateX(-50%)", cursor: "default" }} gap={2}>
          {asset ? (
            <>
              <Tooltip title="Leaderboard rank">
                <Typography color="text.secondary">
                  <b>#{asset.position}</b>
                </Typography>
              </Tooltip>
              <Tooltip title="Leaderboard hype">
                <Typography variant="body2" color={sentimentColor} minWidth="fit-content">
                  {hypeText}
                </Typography>
              </Tooltip>
              <Tooltip title="Leaderboard sentiment">
                <Box>
                  <SentimentAnalysisBadge sentimentString={asset?.sentimentString} />
                </Box>
              </Tooltip>
            </>
          ) : (
            <Typography variant="body2" color="text.secondary">
              Not in Leaderboard
            </Typography>
          )}
          <Tooltip title="Add alert" PopperProps={{sx: { pointerEvents: "none" } }}>
            <Badge
              badgeContent={<img src={AddNotificationWhiteIcon} alt="alert" width="100%" />}
              color="default"
              sx={theme => ({
                position: "relative",
                left: -12,
                // top: 44,
                // right: 78,
                marginLeft: 3,
                "& .MuiBadge-badge": {
                  background: assetRowHasAlerts ? theme.palette.primary.main : theme.palette.text.disabled,
                  width: 24,
                  height: 24,
                  borderRadius: "50%",
                  padding: "6px",
                  pointerEvents: "all",
                  cursor: "pointer",
                  "&:hover": { background: theme.palette.primary.main }
                }
              })}
              onClick={(e) => {
                e.stopPropagation()
                alertsStore.setAddAlertPopup({open: true, selectedAsset: mainStore.currentAssetData?.id})
              }}
            />
          </Tooltip>
        </Box>

      </Box>
      <ArticleListDrawer dateValue={moment(currentDataPoint?.date).format("YYYY-MM-DD")} />
      <ProMembersOnlyDialog open={isProAsset && !isProUser} />
    </Box>
  )
})
