import React, { useEffect, useState } from "react"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box"
import { Dialog, DialogContent, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { PageLogo } from "../../components/common/page-logo"
import { AppTextField } from "components/common/app-controls/app-text-field"
import { AppButton } from "components/common/app-controls/app-button"
import { Disabled } from "components/common/disabled"
import UserStore from "stores/user-store"
import MainStore from "stores/main-store"
import { useNavigate } from "react-router-dom"

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter first name"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter last name"),
  password: Yup.string()
    .min(6, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter your password"),
  confirmPassword: Yup.string()
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value
    })
})

export const FinishSignUpPage = ({}) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))
  const [ mainStore ] = useState(() => MainStore)
  const [ userStore ] = useState(() => UserStore)
  const [ isSaving, setIsSaving ] = useState(false)

  const onInit = async () => {
    try {
      await userStore.validateEmailLink()
      navigate("/finish-signup")
    } catch (ex) {
      mainStore.openToast({
        message: "The verification link is not valid, please verify your email again",
        type: "error",
        position: { vertical: "top", horizontal: "center" }
      })
      navigate("/signup")
    }
  }

  useEffect(() => { onInit() }, [])

  const onSubmit = async (formData) => {
    setIsSaving(true)
    try {
      await userStore.createUser(formData.password, formData.firstName, formData.lastName)
      navigate("/")
    } catch (ex) {
      mainStore.openToast({
        message: "Oops, something happened, please try again",
        type: "error",
        position: { vertical: "top", horizontal: "center" }
      })
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Dialog open PaperProps={{ sx: { borderRadius: "60px" } }} BackdropProps={{ sx: { transition: "none !important" }}} fullWidth>
      <DialogContent>
        <Formik
          initialValues={{ firstName: "", lastName: "", password: "", confirmPassword: "" }}
          validationSchema={ValidationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
        >
          {({ errors, setValues, values }) => (
            <Form>
              <Disabled when={isSaving}>
                <Box width={1} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                  <PageLogo />

                  <Typography variant="h6" width={1} align="center" fontWeight="700" mt={5} fontSize={sm ? 15 : 20}>
                    Great!
                  </Typography>

                  <Typography variant="body1" maxWidth={sm ? 250 : 320} align="center" fontSize={sm ? 12 : 15}>
                    You've been verified successfully, <br /> now let's continue signing you in
                  </Typography>

                  <Box mt={sm ? 1 : 2} />

                  <AppTextField
                    label="First name"
                    placeholder="Enter your first name"
                    value={values.firstName}
                    onChange={(firstName) => setValues({ ...values, firstName })}
                    error={errors.firstName as string}
                  />

                  <AppTextField
                    label="Last name"
                    placeholder="Enter your last name"
                    value={values.lastName}
                    onChange={(lastName) => setValues({ ...values, lastName })}
                    error={errors.lastName as string}
                  />

                  <AppTextField
                    label="Password"
                    placeholder="Enter your password"
                    value={values.password}
                    onChange={(password) => setValues({ ...values, password })}
                    error={errors.password as string}
                    password
                  />

                  <AppTextField
                    label="Confirm Password"
                    placeholder="Re-Enter your password"
                    value={values.confirmPassword}
                    onChange={(confirmPassword) => setValues({ ...values, confirmPassword })}
                    error={errors.confirmPassword as string}
                    password
                  />

                  <AppButton showLoader={isSaving}>
                    Signup
                  </AppButton>

                  <Box mt={sm ? 1 : 2} />

                </Box>
              </Disabled>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
