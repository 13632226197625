import React from "react"
import Box from "@mui/material/Box"
import userStore from "../../stores/user-store"
import { Star, StarOutline } from "@mui/icons-material"
import { Tooltip } from "@mui/material"

type Props = {
  assetId: number
}

export const AddAssetToFavoritesButton = (props: Props) => {
  const isFavorite = userStore.currentUserData?.favoriteAssets?.includes(props.assetId)

  return (
    <Tooltip title={isFavorite ? "Remove favorite" : "Add favorite"}>
      <Box
        sx={!isFavorite && {
          "&:hover > .fav-off": { display: "none" },
          "&:hover > .fav-on": { display: "flex" },
          ".fav-off": { display: "flex" },
          ".fav-on": { display: "none" }
        }}
        onClick={(e) => {
          e.stopPropagation()
          userStore.toggleUserFavoriteAsset(props.assetId)
        }}
      >
        <StarOutline className="fav-off" sx={{ display: !isFavorite ? "flex" : "none" }} />
        <Star className="fav-on" color={isFavorite ? "primary" : "inherit"} sx={{ display: "flex" }} />
      </Box>
    </Tooltip>
  )
}
