import React from "react"
import Box from "@mui/material/Box"
import { Typography } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import MainStore from "stores/main-store"
import { ArticleListDrawer } from "components/article-list-drawer/article-list-drawer"
import moment from "moment"
import { observer } from "mobx-react"
import { ProMembersOnlyDialog } from "components/common/pro-members-only-dialog"
import userStore from "../../stores/user-store"

export const ArticlePage = observer(({}) => {
  const navigate = useNavigate()
  const { assetId, articleId } = useParams()
  const [ mainStore ] = useState(() => MainStore)
  const [ articleText, setArticleText ] = useState("Waiting for article...")

  useEffect(() => { initArticle() }, [ assetId, articleId ])

  const initArticle = async () => {
    mainStore.getAssetData(assetId)
    if (!articleId) {
      if (!mainStore.currentAssetArticlesFlatten?.length) {
        await mainStore.getAssetArticles(assetId)
      }
      const firstArticleId = mainStore.currentAssetArticlesFlatten[0].id
      navigate(`/article/${assetId}/${firstArticleId}`)
    }
    const articleText = await mainStore.getAssetArticleText(assetId, articleId)
    setArticleText(articleText)
  }

  if (!mainStore.currentArticle) return null

  const isProAsset = mainStore.currentAssetData?.asset?.isPro
  const isProUser = userStore.currentUserData?.isProUser

  return (
    <Box
      width={1} height={1} display="flex" position="relative" overflow="visible"
      style={{
        filter: (isProAsset && !isProUser) && "blur(5px)",
        userSelect: (isProAsset && !isProUser) && "none",
        pointerEvents: (isProAsset && !isProUser) && "none",
      }}
    >
      <Box
        maxWidth={600}
        width={mainStore.isMobile ? 1 : "calc(100% - 420px)"}
        position="relative"
        overflow="auto"
        p={mainStore.isMobile ? 4 : 6}
        pb={6}
      >
        <Typography variant="h5" fontSize={28} color="text.primary" fontWeight="600">
          {mainStore.currentArticle.headline}
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{opacity: 0.7}} mt={1}>
          {moment(mainStore.currentArticle.datetime).format("YYYY/MM/DD")} | {mainStore.currentArticle.textSource}
        </Typography>
        <Box width={mainStore.isMobile ? 0.5 : 325} height="1px" bgcolor="text.primary" sx={{opacity: 0.2}} my={2} />
        <Typography variant="body2" color="text.secondary" mt={1}>{articleText}</Typography>
      </Box>
      <ArticleListDrawer dateValue="all" articleId={articleId} />
      <ProMembersOnlyDialog open={isProAsset && !isProUser} />
    </Box>
  )
})
