import React, { useState } from "react"
import Box from "@mui/material/Box"
import { Dialog, DialogContent, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { PageLogo } from "../../components/common/page-logo"
import { AppTextField } from "components/common/app-controls/app-text-field"
import { AppButton } from "components/common/app-controls/app-button"
import { UserPageFooter } from "./components/user-page-footer"
import * as Yup from "yup"
import { Form, Formik } from "formik"
import { Disabled } from "components/common/disabled"
import UserStore from "stores/user-store"
import MainStore from "stores/main-store"
import { parseFirebaseErrorMessage } from "utils/helpers"

export const ResetPasswordPage = ({}) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))
  const [ userStore ] = useState(() => UserStore)
  const [ mainStore ] = useState(() => MainStore)
  const [ isSaving, setIsSaving ] = useState(false)
  const [ isDone, setIsDone ] = useState(false)

  const onSubmit = async (formData) => {

    setIsSaving(true)

    try {
      await userStore.sendPasswordResetEmail(formData.email)
      setIsDone(true)
    } catch (ex) {
      if (ex.code === "auth/user-not-found" || ex.code === "auth/invalid-email") {
        mainStore.openToast({
          message: "The email you've entered does not exists",
          type: "error",
          position: { vertical: "top", horizontal: "center" }
        })
      } else {
        const message = parseFirebaseErrorMessage(ex.message)
        mainStore.openToast({
          message: message || "Oops, something happened, please try again",
          type: "error",
          position: { vertical: "top", horizontal: "center" }
        })
      }
    } finally {
      setIsSaving(false)
    }
  }

  const PleaseCheckEmail = () => (
    <Dialog open PaperProps={{ sx: { borderRadius: "60px" } }} BackdropProps={{ sx: { transition: "none !important" } }} fullWidth>
      <DialogContent>
        <Box width={1} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <PageLogo />

          <Typography variant="h6" width={1} align="center" fontWeight="700" mt={5} fontSize={sm ? 15 : 20}>
            We're on the way!
          </Typography>

          <Typography variant="body1" maxWidth={sm ? 250 : 320} align="center" fontSize={sm ? 12 : 15}>
            Check your inbox for an email from us and press the link to reset your password
          </Typography>

          <UserPageFooter footerType="login" />

        </Box>
      </DialogContent>
    </Dialog>
  )

  if (isDone) return <PleaseCheckEmail />

  return (
    <Dialog open PaperProps={{ sx: { borderRadius: "60px" } }} BackdropProps={{ sx: { transition: "none !important" } }} fullWidth>
      <DialogContent>
        <Formik
          initialValues={{ email: "" }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={
            Yup.object().shape({
              email: Yup.string()
                .email("Please enter a valid email address")
                .required("Please enter a valid email address")
            })}
          onSubmit={onSubmit}
        >
          {({ errors, setValues, values }) => (
            <Form>
              <Disabled when={isSaving}>
                <Box width={1} display="flex" justifyContent="center" alignItems="center" flexDirection="column">

                  <PageLogo />

                  <Typography variant="h6" width={1} align="center" fontWeight="700" mt={2} fontSize={sm ? 15 : 20}>
                    Lost your password?
                  </Typography>

                  <Typography variant="body1" maxWidth={sm ? 250 : 320} align="center" fontSize={sm ? 12 : 15}>
                    Fear not. We’ll email you a link to reset your password.
                  </Typography>

                  <Box mt={sm ? 1 : 2} />

                  <AppTextField
                    value={values.email}
                    onChange={(email) => setValues({ email })}
                    label="Email"
                    placeholder="Enter your Email address"
                    error={errors.email as string}
                  />

                  <AppButton showLoader={isSaving}>
                    Reset Password
                  </AppButton>

                  <UserPageFooter footerType="login" />

                </Box>
              </Disabled>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
