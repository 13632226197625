import "react-hot-loader/patch"
import React from "react"
import ReactDOM from "react-dom"
import { AppContainer } from "react-hot-loader"
import { RootContainer } from "./root-container"

const rootEl = document.getElementById("root")

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <RootContainer/>
    </AppContainer>,
    rootEl
  )
}

render()

if (module.hot) {
  module.hot.accept('./root-container', () => { render() });
}
